import moment from 'moment-timezone'
import React from 'react'

function MessageCard({ message, handleMessageClick, activeMessage = {} }) {
    return (
        <div
            className={`flex flex-col items-start gap-4 border-b-2 border-b-[#253352] p-4 cursor-pointer ${activeMessage?.id === message?.id
                ? 'md:border-l-2 border-l-[#26DDB9]'
                : ''
                }`}
            onClick={() => handleMessageClick(message)}
        >
            <div className='flex items-center gap-4 w-full'>
                {/* Circular profile icon */}
                <div
                    className={`flex items-center justify-center w-10 h-10 rounded-xl text-white font-bold ${activeMessage?.id === message?.id ? 'bg-[#26DDB9]' : 'bg-[#725AF4]'
                        }`}>
                    {message?.sender?.charAt(0)}
                </div>

                <div className="flex-1">
                    <h3 className="font-bold text-white">{message.sender}</h3>
                    <h3 className="text-md font-normal text-white">{activeMessage ? message?.subject?.length > 30 ? message?.subject?.substring(0, 30) + '...' : message?.subject : message?.subject}</h3>
                </div>

                {/* Date on the right */}
                <div className="text-[#AEAEAE] text-xs w-fit">{message.date ? moment(message.data).format('MMM Do') : ''}</div>
            </div>

            {/* Message content */}
            <p className="text-[#AEAEAE] text-start text-wrap">{message?.body?.substring(0, 100) + '...' }</p>
        </div>
    )
}

export default MessageCard