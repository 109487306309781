import React, { useState } from 'react';
import CourseCard from './CourseCard'; // Adjust the path as needed
import SmartContracts from '../../assets/images/landing/smart-contracts.png';
import Enterprise from '../../assets/images/landing/enterprise-grade.png';
import FullstackBlockChain from '../../assets/images/landing/fullstack-blockchain.png';

const CoursesSection = () => {
    const [courses] = useState([
        {
            title: 'Full-Stack Blockchain Development',
            description: 'Comprehensive solutions covering everything from protocol design to dApp development.',
            imgSrc: FullstackBlockChain,
        },
        {
            title: 'Smart Contracts Expertise',
            description: 'Secure, robust smart contracts tailored to your business needs.',
            imgSrc: SmartContracts,
        },
        {
            title: 'Enterprise-Grade Blockchain Solutions',
            description: 'Scalable blockchain systems built for industries ranging from finance to healthcare.',
            imgSrc: Enterprise,
        },
    ]);

    return (
        <div
            className="flex flex-row gap-16 justify-between align-center md:py-36 md:px-16
            below-md:flex-col below-md:p-8 below-md:gap-24"
        >
            {courses.map((course, index) => (
                <CourseCard
                    key={index}
                    title={course.title}
                    description={course.description}
                    imgSrc={course.imgSrc}
                    isGradient={index === 1 ? true : false}
                />
            ))}
        </div>
    );
};

export default CoursesSection;
