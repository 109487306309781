import React, { useState } from 'react';
import OurMission from '../../assets/images/landing/our-mission.png';
import CoreValues from '../../assets/images/landing/core-values.png';
import OurJourney from '../../assets/images/landing/our-journey.png';
import OurVision from '../../assets/images/landing/our-vision.png';

const AboutUs = () => {
    const [cards] = useState([
        {
            title: 'Our Mission',
            image: OurMission,
            description:
                'Havok Labs was founded with a single goal: to drive blockchain innovation and deliver high-quality, secure, and scalable solutions to businesses worldwide.',
            linkText: 'Learn More',
        },
        {
            title: 'Our Vision',
            image: OurVision,
            description:
                'We envision a world where blockchain is a fundamental part of business operations, enabling transparency, security, and decentralization.',
            linkText: 'Learn More',
        },
        {
            title: 'Core Values',
            image: CoreValues,
            description:
                'Our core values drive blockchain innovation and deliver high-quality, secure, and scalable solutions to businesses worldwide.',
            linkText: 'Learn More',
        },
        {
            title: 'Our Journey',
            image: OurJourney,
            description:
                'At Havok Labs, we deliver cutting-edge, full-stack blockchain services, from smart contracts to decentralized applications (dApps).',
            linkText: 'Learn More',
        },
    ]);

    return (
        <>
            <div className="flex flex-col gap-12 below-md:hidden mt-5">
                {cards.reduce((rows, card, index) => {
                    const rowIndex = Math.floor(index / 2); // Two cards per row
                    if (!rows[rowIndex]) rows[rowIndex] = [];
                    rows[rowIndex].push(card);
                    return rows;
                }, []).map((row, rowIndex) => (
                    <div key={rowIndex} className="flex flex-row gap-12">
                        {row.map((card, index) => (
                            <div className='hover:bg-gradient-to-r from-[#D724FC] via-[#D724FC] to-[#7770FF] p-px rounded-2xl w-full'>
                                <div key={index} className="aboutus-card-new bg-[#0D1437] w-full h-full rounded-2xl">
                                    <div className="flex flex-row justify-start items-center gap-10">
                                        <div className="ourmission-img-container">
                                            <img src={card.image} alt={card.title} />
                                        </div>
                                        <span className="text-white font-semibold text-2xl">{card.title}</span>
                                    </div>
                                    <div>
                                        <span className="w-[40%] text-white font-normal text-medium text-opacity-50">
                                            {card.description}
                                        </span>
                                    </div>
                                    {/* <div>
                                        <span className="get-started font-bold text-medium">{card.linkText}</span>
                                    </div> */}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className="md:hidden flex flex-row gap-8 overflow-x-auto scrollbar-hidden p-8">
                {cards.map((card, index) => (
                    <div key={index} className="aboutus-card">
                        <div className="flex flex-row justify-start items-center gap-10">
                            <div className="ourmission-img-container">
                                <img
                                    style={{ height: 30, width: 30 }}
                                    src={card.image}
                                    alt={card.title}
                                />
                            </div>
                            <span className="text-white font-semibold text-xl">{card.title}</span>
                        </div>
                        <div>
                            <span className="w-[40%] text-white font-normal text-medium text-opacity-50">
                                {card.description}
                            </span>
                        </div>
                        {/* <div>
                            <span className="get-started font-bold text-medium">{card.linkText}</span>
                        </div> */}
                    </div>
                ))}
            </div>
        </>

    );
};

export default AboutUs;
