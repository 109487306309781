import moment from 'moment-timezone';
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "../Styles/Dashboard.css";
import { capitalize } from "@mui/material";
import { ChevronLeft, ChevronRight, Search } from 'lucide-react';
import biticon from '../assets/images/trade/biticon.svg';
import litecoin from '../assets/images/trade/litecoin.svg';
import ethereum from '../assets/images/trade/ethereum.svg';
import solana from '../assets/images/trade/solana.svg';
import RedDownIcon from '../assets/images/trade/reddownicon.svg';
import GreenUpIcon from '../assets/images/trade/greepupicon.svg';
import TrendImage from '../assets/images/trade/sampleTrendingImage.svg'
import { getFirstName } from '../services/common.utils';
// import sampleData from '../Helpers/sampleMarketData.json'
import graph from '../assets/images/market/graph.svg'
import graphRed from '../assets/images/market/graphRed.svg'
import btcIcon from '../assets/images/btc.svg'
import { toast } from 'react-toastify';
import sampleData from '../Helpers/sampleMarketData.json'

const Trade = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 20;
  const [searchValue, setSearchValue] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const getMarketData = async () => {
    try {
      setLoading(true);
      let token = Cookies.get('token');

      if (!token) {
        console.error("Token is missing. Please log in again.");
        return;
      }

      const start = (currentPage - 1) * recordsPerPage + 1;
      const api = `${process.env.REACT_APP_BACKEND}cryptos?convert=USD&length=100&start=${start}${searchValue ? `&searchValue=${encodeURIComponent(searchValue)}` : ''
        }`;

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(api, { headers });
      setData(response?.data?.cryptoCurrency || []);
      setTotalCount(response?.data?.totalCount || 0);
      setLoading(false);

    } catch (error) {
      setData([]);
      toast.error('Error fetching market data', { theme: 'dark' })
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  // Debounce logic
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 500); // 500ms debounce time

    return () => clearTimeout(handler); // Cleanup on value change
  }, [searchValue]);

  // Fetch data when debouncedSearchValue changes
  useEffect(() => {
    const fetchMarketData = () => {
      getMarketData(currentPage, debouncedSearchValue); // Pass debounced value
    };

    // Fetch immediately on component mount and debounce change
    fetchMarketData();

    // Fetch every 1 minute
    const intervalId = setInterval(fetchMarketData, 60000);

    // Clear interval on unmount
    return () => clearInterval(intervalId);
  }, [currentPage, debouncedSearchValue]);

  const currentRecords = data.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);

  const renderTableData = () => {
    // let renderData = searchValue ? searchUsers(searchValue) :
    // 				currentPage ? data.slice(((currentPage-1)*5), (currentPage*5)) : data;
    return (
      <div className='mobileTable userDataTable ctTable'>
        {
          isMobile ? (
            <div className="flex flex-col space-y-4">
              {loading ? (
                <div className="skeleton space-y-2">
                  {[...Array(8)].map((_, idx) => (
                    <div key={idx} className="skeleton-line h-4 bg-gray-300 rounded-md"></div>
                  ))}
                </div>
              ) : (
                currentRecords?.slice(0, recordsPerPage)?.map((item, index) => (
                  <div
                    key={index}
                    className="bg-[#212F4C] p-4 rounded-lg shadow-md text-white cursor-pointer md:hidden" // Only shows on mobile
                    onClick={() => navigate('/market/' + item.id)}
                  >
                    <div className="flex justify-between items-center mb-4">
                      <div className='flex gap-2 items-center'>
                        <img src={item.logo} alt={item.id} className='w-8 h-8' />
                        <div className='flex flex-col gap-1'>
                          <div className="text-lg font-bold">{item.name} / {item.symbol}</div>
                          <p className='text-xs'>Today up to <span className={`${item.percentChange24h >= 0 ? 'text-[#37CBB0]' : 'text-red-500'}`}>{item.percentChange24h}%</span></p>
                        </div>
                      </div>
                      <div>
                        {
                          item.percentChange24h < 0 ?
                            <img src={graphRed} alt='graphRed' /> :
                            <img src={graph} alt='graph' />
                        }
                      </div>
                    </div>
                    <div className="space-y-4 p-3">
                      {/* <div className="flex flex-col justify-start gap-2">
                        <span className="text-[#9197A4]">Change</span>
                        <span className={`text-lg ${item.percentChange24h < 0 ? 'text-red-500' : 'text-teal-400'}`}>
                          {item.percentChange24h > 0 ? '+' : ''}{item.percentChange24h}%
                        </span>
                      </div> */}
                      <div className="flex flex-col justify-start gap-2">
                        <span className="text-[#9197A4]">1hr Change</span>
                        <span className={`text-lg ${item.percentChange1h < 0 ? 'text-[#FD3549]' : 'text-[#3CC8C8]'}`}>
                          {item.percentChange1h > 0 ? '+' : ''}{item.percentChange1h?.toLocaleString('en-US') || '0.00'}%
                        </span>

                        <span className="text-[#9197A4]">24hr Change</span>
                        <span className={`text-lg ${item.percentChange24h < 0 ? 'text-[#FD3549]' : 'text-[#3CC8C8]'}`}>
                          {item.percentChange24h > 0 ? '+' : ''}{item.percentChange24h?.toLocaleString('en-US') || '0.00'}%
                        </span>

                        <span className="text-[#9197A4]">7d Change</span>
                        <span className={`text-lg ${item.percentChange7d < 0 ? 'text-[#FD3549]' : 'text-[#3CC8C8]'}`}>
                          {item.percentChange7d > 0 ? '+' : ''}{item.percentChange7d?.toLocaleString('en-US') || '0.00'}%
                        </span>
                      </div>

                      <div className="flex flex-col justify-start gap-2">
                        <span className="text-[#9197A4]">Volume</span>
                        <span>${item.volume24h?.toLocaleString('en-US') || '0.00'}</span>
                      </div>
                      <div className="flex flex-col justify-start gap-2">
                        <span className="text-[#9197A4]">Market Cap</span>
                        <span>${item.marketCap?.toLocaleString('en-US') || '0.00'}</span>
                      </div>
                      <div className="flex flex-col justify-start gap-2">
                        <span className="text-[#9197A4]">Price</span>
                        <span>${item.price?.toLocaleString('en-US') || '0.00'}</span>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>

          ) :
            <div className="table-container px-4 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent overflow-x-auto whitespace-nowrap" style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
              <table className="table">
                <thead>
                  <tr>
                    <th className="userDataTableHeader text-start max-w-[20px]">#</th>
                    <th className="userDataTableHeader text-center w-[350px] alignLeft">Name</th>
                    <th className="userDataTableHeader text-end alignRight">Price</th>
                    <th className="userDataTableHeader text-end alignRight">1h %</th>
                    <th className="userDataTableHeader text-end alignRight">24h %</th>
                    <th className="userDataTableHeader text-end alignRight">7d %</th>
                    <th className="userDataTableHeader text-end alignRight ctwidth">Market Cap</th>
                    <th className="userDataTableHeader text-end alignRight ctwidth">Volume (24h)</th>
                    <th className="userDataTableHeader text-end alignRight ctwidth" style={{ minWidth: 300 }}>Circulating Supply</th>
                    {/* <th className="userDataTableHeader text-end">Change</th> */}
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <div className="skeleton">
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                    </div>
                  ) : (
                    currentRecords?.slice(0, recordsPerPage)?.map((item, index) => (
                      <tr
                        key={index}
                        className="text-white cursor-pointer items-center text-md"
                        onClick={() => {
                          navigate('/market/' + item.id);
                        }}
                      >
                        <td className="text-white text-lg text-center max-w-[20px]">
                          {index + 1}
                        </td>
                        {/* Coin with Icon - Left Aligned */}
                        <td data-label="Coin" className="text-left flex items-center text-white text-lg  w-[350px]">
                          <div className='flex gap-2 items-center'>
                            <img
                              src={item.logo}
                              alt={item.name}
                              className="w-8 h-8 mr-2"
                            />
                            <div className='flex flex-col gap-0.5 items-start'>
                              <span className='text-md break-all truncate md:max-w-[150px]' title={item.name}>{item.name}</span>
                              <span className='text-xs text-slate-200'>{item.symbol}</span>
                            </div>
                          </div>

                        </td>

                        {/* Price - Right Aligned */}
                        <td data-label="Price" className="text-right text-white text-lg alignRight">
                          ${item.price?.toLocaleString('en-US') || '0.00'}
                        </td>

                        <td data-label="1hr %" className={`text-right  ${item.percentChange1h < 0 ? 'text-[#FD3549]' : 'text-[#3CC8C8]'
                          } text-lg alignRight`}>
                          {item.percentChange1h?.toLocaleString('en-US') || '0.00'}%
                        </td>
                        <td data-label="24hr %" className={`text-right ${item.percentChange1h < 0 ? 'text-[#FD3549]' : 'text-[#3CC8C8]'
                          } text-lg alignRight`}>
                          {item.percentChange24h?.toLocaleString('en-US') || '0.00'}%
                        </td>
                        <td data-label="7d %" className={`text-right ${item.percentChange1h < 0 ? 'text-[#FD3549]' : 'text-[#3CC8C8]'
                          } text-lg alignRight`}>
                          {item.percentChange7d?.toLocaleString('en-US') || '0.00'}%
                        </td>

                        {/* Market Cap - Right Aligned */}
                        <td data-label="Market Cap" className="text-right text-white text-lg alignRight ctwidth">
                          ${item.marketCap?.toLocaleString('en-US') || '0.00'}
                        </td>

                        {/* 24h Volume - Right Aligned */}
                        <td data-label="24h Volume" className="text-right text-white text-lg alignRight ctwidth">
                          ${item.volume24h?.toLocaleString('en-US') || '0.00'}
                        </td>

                        <td data-label="Circulating Supply" className="text-right text-white text-lg alignRight ctwidth" style={{ minWidth: 300, textAlign: 'end' }}>
                          {item.circulatingSupply ? item.circulatingSupply?.toLocaleString('en-US') + ' ' + item.symbol : '0.00'}
                        </td>

                        {/* Change - Right Aligned */}
                        {/* <td
                          data-label="Change"
                          className={`alignRight text-lg ${item.percentChange24h < 0 ? 'text-[#FD3549]' : 'text-[#3CC8C8]'
                            }`}
                        >
                          {item.percentChange24h > 0
                            ? '+' + item.percentChange24h.toFixed(2) + '%'
                            : item.percentChange24h.toFixed(2) + '%'}
                        </td> */}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
        }
      </div>
    );
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getMarketData(page);
  };

  const totalPages = Math.ceil(totalCount / recordsPerPage);
  // const totalPagesUser = Math.ceil(userDetails / recordsPerPageUser);

  const renderPagination = () => {
    const buttons = [];

    // Add Previous button if currentPage is greater than 1
    if (currentPage > 1) {
      buttons.push(
        <button
          className="font-medium text-[#32CCCB]"
          key="prev"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <ChevronLeft color="white" />
        </button>
      );
    }

    // Calculate total pages based on the total number of records
    const totalPages = Math.ceil(totalCount / recordsPerPage); // Ensure `totalRecords` is passed to your component.

    // Determine the range of pagination buttons to show
    const visiblePages = 5; // Number of visible pages at a time
    let startPage = Math.max(currentPage - Math.floor(visiblePages / 2), 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    // Adjust startPage if the total number of pages is less than visiblePages
    if (endPage - startPage + 1 < visiblePages) {
      startPage = Math.max(endPage - visiblePages + 1, 1);
    }

    // Render pagination buttons for each page
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          className={`font-medium ${currentPage === i ? 'text-[#32CCCB]' : 'text-white'}`}
          key={i}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    // Add Next button if currentPage is less than totalPages
    if (currentPage < totalPages) {
      buttons.push(
        <button
          className="font-medium text-[#32CCCB]"
          key="next"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <ChevronRight color="white" />
        </button>
      );
    }

    return buttons;
  };


  return (
    <div>
      <div className='flex flex-row justify-end items-center w-full relative'>
        {/* <p className='hidden md:block font-semibold text-2xl capitalize text-white text-center md:text-start'>
          Hello <span className='text-[#3CC8C8]'>{getFirstName()}</span>
        </p> */}
        <div className='absolute top-3 md:top-0 md:block md:relative mb-5'>
          <Search className='absolute left-3 top-2.5' size={18} color='#D6DAE0' />
          <input
            className='ps-10 pe-4 py-2 rounded-full text-[#D6DAE0] bg-[#37445E] outline-none'
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder='Search'
          />
        </div>
      </div>
      <div className='w-full space-y-3'>
        {/* <p className='md:hidden font-semibold text-2xl capitalize text-white text-center md:text-start'>
          Hello <span className='text-[#3CC8C8]'>{getFirstName()}</span>
        </p> */}
        {/* <div className='flex flex-row justify-between items-center w-full'>
          <span className='text-[#FFF] text-sm mt-4 mb-4'>TOP TRENDING COINS</span>
          <div className='md:hidden block relative'>
            <Search className='absolute left-3 top-2.5' size={18} color='#D6DAE0' />
            <input
              className='ps-10 pe-4 py-2 rounded-full text-[#D6DAE0] bg-[#37445E]'
              placeholder='Search'
            />
          </div>
        </div>
        <div className='flex flex-row flex-nowrap gap-4 overflow-x-auto md:overflow-hidden scrollbar-none'>
          {
            tredingCoins?.map((coin) => {
              return (
                <TrendingCoin coin={coin} />
              )
            })
          }
        </div>
        <div className='flex flex-row flex-nowrap gap-4 overflow-x-auto md:overflow-hidden scrollbar-none'>
          {
            trendingCards?.map((card) => {
              return (
                <TrendingCard card={card} />
              )
            })
          }
        </div> */}
        <div className='md:bg-[#212F4C] rounded-xl'>
          <div className='p-4 py-4 flex justify-between'>
            <span className='text-[#FFF] text-xl '>Market</span>
            {/* <button className='text-[#E4E4E4] text-md md:hidden'>View More</button> */}
          </div>
          {
            renderTableData()
          }
          <div className='flexRow bg-[#202F4C]' style={{ justifyContent: 'space-between', alignItems: 'center', padding: 24 }}>
            <div>
              <span className='paginationResultText'>
                Showing {(currentPage - 1) * recordsPerPage + 1} to {currentPage * recordsPerPage > data.length ? data.length : currentPage * recordsPerPage} of {totalCount} transactions
              </span>
            </div>
            <div className='flexRow' style={{ gap: 16 }}>
              {
                renderPagination()
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Trade;