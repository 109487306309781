/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { ChevronRight, ChevronLeft, Reply } from 'lucide-react';
import { Search, EllipsisVertical } from 'lucide-react';
import filterIcon from '../../assets/images/filter.svg'
import '../../Styles/UserDashboardAdminView.css'
import { getFirstName } from "../../services/common.utils";
import { FaSearch } from "react-icons/fa";
import InquiryResponse from "../Users/InquiryResponse";
import Cookies from 'js-cookie';
import moment from "moment";
import axios from "axios";
import SortInqueries from "./SortInqueries";

const InquiriesHome = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [loading, setLoading] = useState(false);
    const [currentPageUser, setCurrentPageUser] = useState(1);
    const [isOpenResponse, setIsOpenResponse] = useState(false)
    const [selectedInquiryData, setSelectedInquiryData] = useState({})
    const recordsPerPageUser = 10;
    const [searchValue, setSearchValue] = useState('');

    const [userDetails, setUserDetails] = useState([]);
    const [sortby, setSortby] = useState('latest');
    const [totalPagesUser, setTotalPagesUser] = useState(1);
    let token = Cookies.get('token');

    // init
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleReplyAction = (data) => {
        setIsOpenResponse(true);
        setSelectedInquiryData(data);
    }

    const ActionButtons = ({
        item,
        index
    }) => {
        const [isOpen, setIsOpen] = useState(false);
        const dropdownRef = useRef(null);

        // Close dropdown when clicking outside
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                    setIsOpen(false);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => document.removeEventListener('mousedown', handleClickOutside);
        }, []);

        return (
            <div className="relative flex px-2 gap-3 items-center justify-around md:justify-center" ref={dropdownRef}>
                {/* Dropdown Trigger Button */}
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex items-center gap-2 py-2 rounded-full text-white"
                >
                    <EllipsisVertical></EllipsisVertical>
                </button>

                {/* Dropdown Menu */}
                {isOpen && (
                    <div className="absolute md:w-fit ms-[-150px] md:ms-0 top-7 mt-2 w-48 bg-[#2A3B59] rounded-lg shadow-lg py-1 z-10 text-white">
                        <div className="flex justify-between items-center px-4 py-2 border-b border-[#28274D]">
                            <span className="text-sm">Action</span>
                        </div>
                        <div className='w-[100%] flex flex-col items-center justify-start md:px-3'>
                            {/* Update Balance Option */}
                            <button
                                onClick={() => handleReplyAction(item)}
                                className="w-[80%] md:w-full text-white mt-2 px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"
                            >
                                <Reply />
                                Reply
                            </button>

                            {/* Add Funds Option */}
                            {/* <button
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                                className="w-[80%] text-white px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"

                            >
                                View more
                            </button> */}

                            {/* View Option */}
                            <button
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                                className="w-[80%] md:w-full text-white px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"

                            >
                                Resolve Enquiry
                            </button>
                        </div>
                    </div>
                )}


            </div>
        );
    };

    const handlePageChangeUser = (page) => {
        setCurrentPageUser(page);
    }
    const ClientMessage = ({ content }) => {
        const [isExpanded, setIsExpanded] = useState(false);

        return (
            <div>
                <p className={`truncate-text ${isExpanded ? 'expanded' : ''}`}>
                    {content}
                </p>
                {content.split(' ').length > 20 && ( // Only show "Read More" if content is long
                    <span
                        className="read-more-toggle text-[#33CCCC] cursor-pointer"
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        {isExpanded ? 'Read less' : '... Read more'}
                    </span>
                )}
            </div>
        );
    };

    const renderInquiryTableData = () => {

        if (isMobile) {
            // Mobile View
            return (
                <>
                    <div className="flex items-center space-x-4 px-2 py-4 justify-between">
                        <h1 className="text-white text-lg font-semibold">Inquiries</h1>
                        <div className="flex flex-1 justify-end gap-2">
                            <div className="flex items-center space-x-2 bg-[#202F4C] rounded-full px-4 py-2 text-gray-300">
                                <FaSearch />
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="bg-transparent outline-none text-gray-300 placeholder-gray-300"
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                            </div>

                            <button className="flex items-center justify-center w-12 h-12 text-gray-300">
                                <img src={filterIcon} alt="filter" />
                            </button>
                        </div>
                    </div>
                    <div className="space-y-4">
                        {currentUserDetails?.map((item, index) => (
                            <div
                                key={index}
                                className="bg-[#202F4C] p-4 rounded-2xl text-white space-y-2 shadow-md relative"
                            >
                                {/* Actions */}
                                <div className="flex justify-end mt-2">
                                    <ActionButtons item={item} index={index} />
                                </div>
                                {/* Contact Info */}
                                <div className="flex justify-between items-center break-words my-2">
                                    <div className="text-sm">
                                        <div>{item.email}</div>
                                        <div>{item.phone}</div>
                                    </div>
                                    <button className={`${item?.response?.toLowerCase() === 'resolved' ? 'bg-[#33CCCC]' : item.response?.toLowerCase() === 'pending' ? 'bg-[#FD9C35]' : 'bg-red-500'} text-white px-3 py-1 rounded-full capitalize text-sm`}>
                                        {item.response}
                                    </button>
                                </div>

                                {/* Date */}
                                <div className="flex justify-between">
                                    <div className="text-[#33CCCC] text-sm">Date</div>
                                    <div className="text-lg">{item.date}</div>
                                </div>
                                {/* Message */}
                                <div className="text-[#33CCCC] text-sm">Message</div>
                                <div className="text-white">
                                    <ClientMessage content={item?.clientMessage} />
                                </div>


                            </div>
                        ))}
                    </div>
                </>

            );
        }
        else return (
            <div className="mobileTable userDataTable">
                <table className="table table-auto w-full">
                    <thead>
                        <tr>
                            <th className="userDataTableHeader w-1/5 text-left px-4 py-2">Name</th>
                            <th className="userDataTableHeader w-1/5 text-left px-4 py-2">Date</th>
                            <th className="userDataTableHeader w-[250px] text-left px-4 py-2">Contact</th>
                            <th className="userDataTableHeader w-[400px] text-center px-4 py-2">Client Message</th>
                            <th className="userDataTableHeader w-[100px] px-4 py-2" style={{ textAlign: 'center' }}>Status</th>
                            <th className="userDataTableHeader w-[100px] text-right px-4 py-2"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="5" className="p-4 text-center">
                                    <div className="skeleton">
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                    </div>
                                </td>
                            </tr>
                        ) : (
                            currentUserDetails?.map((item, index) => (
                                <tr
                                    key={index}
                                    className="border-b border-gray-700 items-center"
                                >
                                    <td
                                        data-label="Date"
                                        className="text-white text-lg px-4 py-2 text-left w-1/5"
                                    >
                                        {item.username || 'n/a'}
                                    </td>
                                    <td
                                        data-label="Date"
                                        className="text-white text-lg px-4 py-2 text-left w-1/5"
                                    >
                                        {item.date}
                                    </td>
                                    <td
                                        data-label="Contact"
                                        className="break-words text-white text-lg px-4 py-2 text-left w-[250px]"
                                    >
                                        {item.email} <br /> {item.phone}
                                    </td>
                                    <td
                                        data-label="Client Message"
                                        className="text-white text-lg px-4 py-2 text-center w-[400px]"
                                    >
                                        <ClientMessage content={item.clientMessage} />
                                    </td>
                                    <td
                                        data-label="Response"
                                        className="text-white text-lg px-4 py-2 text-center w-[100px]"
                                    >
                                        <div className="flex justify-center">
                                            <button className={`capitalize ${item?.response?.toLowerCase() === 'resolved' ? 'bg-[#33CCCC]' : item.response?.toLowerCase() === 'pending' ? 'bg-[#FD9C35]' : 'bg-red-500'} px-4 py-1 rounded-full`}>
                                                {item.response}
                                            </button>
                                        </div>
                                    </td>
                                    <td
                                        data-label="Actions"
                                        className="text-white text-lg px-4 py-2 text-right w-[100px]"
                                    >
                                        <ActionButtons item={item} index={index} />
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

        );
    };

    const renderPaginationUser = () => {
        const buttons = [];

        // Add Previous button if currentPage is greater than 1
        if (currentPageUser > 1) {
            buttons.push(
                <button className='font-medium text-[#32CCCB]' key="prev" onClick={() => handlePageChangeUser(currentPageUser - 1)}>
                    <ChevronLeft color='white' />
                </button>
            );
        }

        // Calculate the starting page number for pagination
        let startPage = 1;
        if (startPage <= 0) {
            startPage = 1;
        }

        // Calculate the ending page number for pagination
        let endPage = Math.ceil(userDetails?.length / recordsPerPageUser);
        if (endPage > totalPagesUser) {
            endPage = totalPagesUser;
        }

        // Render pagination buttons for each page
        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button className={`font-medium text-white ${currentPageUser === i ? 'text-[#32CCCB]' : ''}`} key={i} onClick={() => handlePageChangeUser(i)}>{i}</button>
            );
        }

        // Add Next button if currentPage is less than totalPages
        if (currentPageUser < totalPagesUser) {
            buttons.push(
                <button className='font-medium text-[#32CCCB]' key="next" onClick={() => handlePageChangeUser(currentPageUser + 1)}>
                    <ChevronRight color='white' />
                </button>
            );
        }

        return buttons;
    }

    // Render wallet data based on the current page
    const currentUserDetails = userDetails.slice((currentPageUser - 1) * recordsPerPageUser, currentPageUser * recordsPerPageUser);

    // fetch inqueries
    const fetchInquiries = async () => {
        setLoading(true);
        try {
            let api = `${process.env.REACT_APP_BACKEND}inquiry?&sortby=${sortby}${searchValue !== '' ? '&searchValue=' + searchValue : ''}&page=${currentPageUser}&recordsPerPage=${recordsPerPageUser}`
            let headers = {
                Authorization: `Bearer ${token}`
            }
            const response = await axios.get(
                api, { headers: headers }
            );
            const data = await response.data;

            setUserDetails(data?.inquiries?.map(e => {
                return {
                    ...e,
                    updatedOn: e?.updatedAt ? moment(e?.updatedAt).format('YYYY-MM-DD') : 'N/A',
                    email: e?.userEmail,
                    phone: e?.userPhoneNumber,
                    response: e?.status,
                    date: e?.createdAt ? moment(e?.createdAt).format('YYYY-MM-DD') : 'N/A',
                    clientMessage: e?.message
                }
            }));
            setTotalPagesUser(data?.recordsFiltered / recordsPerPageUser);
        } catch (error) {
            console.error("Error fetching inquiries:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInquiries();
    }, [currentPageUser, searchValue, sortby]);


    return (
        isOpenResponse ?
            <InquiryResponse data={selectedInquiryData} setIsOpenResponse={setIsOpenResponse} getInquiries={fetchInquiries} /> :
            <div
                className="gap-0 rounded-2xl"
                style={{
                    overflow: 'hidden',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
              

                <div className="mt-5 inquiry-table-container md:bg-[#202F4C] md:p-5 md:rounded-2xl">
                    <div className="record-header hidden md:flex">
                        <span class="record-title hidden md:block">Inquiries</span>
                        <div className='topfeatures'>
                            <div className='relative'>
                                <Search className='absolute left-3 top-[20px]' size={18} color='#D6DAE0' />
                                <input
                                    style={{ fontSize: '1rem' }}
                                    className='ps-10 pe-4 text-md py-2 rounded-full text-[#D6DAE0] bg-[#37445E] outline-none'
                                    // onKeyDown={handleSearchKeyPress}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    placeholder='Search'
                                />
                            </div>
                            <div style={{ width: '100%' }}>
                                <SortInqueries setSortingOption={setSortby} sortingOption={sortby} />
                            </div>
                        </div>
                    </div>
                    {renderInquiryTableData()}
                    <div className='flexRow bg-[#202F4C]' style={{ justifyContent: 'space-between', alignItems: 'center', padding: 24 }}>
                        <div>
                            <span className='paginationResultText'>
                                Showing {(currentPageUser - 1) * 10 + 1} to {currentPageUser * 10 > userDetails.length ? userDetails.length : currentPageUser * 10} of {userDetails.length} Inquiries
                            </span>
                        </div>
                        <div className='flexRow' style={{ gap: 16 }}>
                            {
                                renderPaginationUser()
                            }
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default InquiriesHome