import React, { useRef, useEffect, forwardRef } from "react";
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import BlockChain from '../assets/images/landing/banner.gif';
// import BlockChain from '../assets/images/landing/blockchain.png';
import FullstackBlockChain from '../assets/images/landing/fullstack-blockchain.png';

import WhyHavocLabs from '../assets/images/landing/timeline.gif';
import RightArrowGradient from '../assets/images/landing/right-arrow.png';

import Nick from '../assets/images/landing/nickpp.png';
import Insight1 from '../assets/images/landing/insight-1.png';
import Insight2 from '../assets/images/landing/insight-2.png';
import Insight3 from '../assets/images/landing/insight-3.png';
import partners from '../assets/images/landing/partner.gif';
import blockchain_consulting from '../assets/images/landing/blockchain_consulting.png';
import dApps from '../assets/images/landing/dApps.png';
import tokendev from '../assets/images/landing/tokendev.png';
import enterprise from '../assets/images/landing/enterprise.png';
import contract from '../assets/images/landing/contract.png';
import integration from '../assets/images/landing/integration.png';
import Button from '@mui/material/Button';
import '../Styles/Landing.css';
import { useState } from 'react';
import { TextField } from '@mui/material';
import AboutUs from "./Landing/AboutUs";
import CoursesSection from "./Landing/CourseSection";
import Services from "./Landing/Services";


const LandingPage = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const section4Ref = useRef(null);
    const section41Ref = useRef(null);
    const section5Ref = useRef(null);
    const section6Ref = useRef(null);
    const section7Ref = useRef(null);

    useEffect(() => {
        if (ref) {
            ref.current = {
                section1: section1Ref.current,
                section2: section2Ref.current,
                section3: section3Ref.current,
                section4: section4Ref.current,
                section41: section41Ref.current,
                section5: section5Ref.current,
                section6: section6Ref.current,
                section7: section7Ref.current
            };
        }
    }, [ref]);

    const scrollToSolutions = () => {
        // Scroll to the element
        section4Ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    const [solutions, setSolutions] = useState([
        {
            title: 'Finance',
            desc: 'Revolutionize payments, settlements, and trading platforms with blockchain technology. Reduce fraud and improve transparency in financial transactions.'
        },
        {
            title: 'Healthcare',
            desc: 'Secure patient data, improve transparency in medical records, and enhance supply chain management with blockchain technology.'
        },
        {
            title: 'Supply Chain',
            desc: 'Increase traceability, reduce fraud, and improve logistics by integrating blockchain-powered solutions into your supply chain.'
        },
        {
            title: 'Real Estate',
            desc: 'Streamline property transactions, tokenization of real estate assets, and contract management using blockchain solutions.'
        },
        {
            title: 'Gaming & Entertainment',
            desc: 'Build NFTs, in-game currencies, and digital asset marketplaces to power the next generation of entertainment.'
        }
    ]);

    const [techstacks, setTechStacks] = useState([
        {
            title: 'Blockchain Protocols',
            desc: 'Havok Labs works with leading blockchain platforms, including Ethereum, Solana, Binance Smart Chain, Polkadot, Hyperledger Fabric, and Avalanche.'
        },
        {
            title: 'Development Tools',
            desc: 'We specialize in Solidity, Rust, Web3.js, Truffle, and Hardhat for blockchain development.'
        },
        {
            title: 'Frameworks',
            desc: 'IPFS for decentralized storage, Chainlink for oracles, and Layer 2 scaling solutions like Optimism and zk-Rollups.'
        }
    ])

    const teamMembers = [
        {
            name: "Lysander Thorne",
            title: "Chief Blockchain Officer",
            description: "With years of blockchain experience...",
            img: 'https://s3-alpha-sig.figma.com/img/cecf/ecbd/47e5af69df9bc697feea5ba9e382e7b5?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jSbkH5GDZxiJ8BJLWUfUnbDDp8U8JOJOuvIim~69y9Khc92VH9k50AYYtVQxqCtREO59p5JmVykoZUL53sLL6F~gbM3CB5DbLVEa9Uz2RwgS5Ma2FRnOvPLyiuJPVYPTqRDyvT-0K3f7OrKiVV~JbYVYn6Qs~wdsALH42n~I2efulAoSxvvwMS5fjnSp1B5irCM6cg1Of6g6E-ALt0PNTeYSNs75rEoS2ImbF5K4S1W3~hL4v~wKfIml3Er-J13Nwyen~6MCkZeBuJb-3VU9NXyFDjLekuOs5m4dc0JOKf-onEVRZvjTJTsFhzQ7JZPk-AX3IC8fxtE~ZusKU6ObLg__',
        },
        {
            name: "Nick Callahan",
            title: "Lead Smart Contract Developer",
            description:
                "Together, we bring a wealth of expertise to ensure your project's success.",
            img: Nick,
            highlighted: true, // Middle card with special styling
        },
        {
            name: "Michele Voss",
            title: "Blockchain Strategist",
            description:
                "Our team of strategists & developers are dedicated to pushing...",
            img: 'https://s3-alpha-sig.figma.com/img/65b5/fcaf/a2a297cac6c26bfedb8d0e5b412feeac?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ODzWs0Eb9-ovKEh1ij6w7gJliDzBIKF9CD-XMHt9TZ~LVIvvzwClr7UHD4vucAiBnSnNNZvB9DoGmbjyJA0cM1VM8VJdSwEHwY23oqhiwAgtUjYy~sUG-blkINTBiWBMUvFB5BQmz9iRNLiLmAw5DsOhKHJ7xo31j4bcG0bIzQjWtxEg4QPEP8ieAIxgmG2GDFi0WfVfvjDJRhLoCi1E16W3nwDVsMsP2eFGgO904Fnl1SC9Fi3U-sPDwGrkOpqrfwqVPvOM2g0mAJWsieMRzRDPVHaocCPBW5pxuC-FeHZ5U9krA39FZHke6UXDeSUoOLYtpOF9WXnm1eIqleHzSA__',
        },
        {
            name: "Zephyr Jones",
            title: "Blockchain Innovator",
            description: "We provide the most creative blockchain solutions...",
            img: 'https://s3-alpha-sig.figma.com/img/cb87/2bad/548e48b1d683ccd4c362162b0f881867?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XAw4Dh0Bx-0Djs~mM8mU-Sj1cOGhte0l~cEP0TsX5QTkxLUCZM0-SlQA8pMepiFqaOHm~Kah9JjD3lHtlEudJuAVHMaX~QqUaf1jSAKWnnNGyq3eAoaIw~ijZAgS6CcIqOXMLy-sx19k-~Rm1UQ3g3FIi5lYHT6QwCaBJR3zPhyqNXBrv-q5PiS6UAi8~oh~zWha4B59fxyY36Ah4Rltrs~VgzhQ7sWC~NEGUM3eRg5g9KhAb1TRzMBZrxZSPVKEa~LaYFpz9MrQtlWm1YYMPwHvlePcWScKlPqpSXrmrPgM3EzKUl5DgY0Gx9ZETKdIBWbtJXyP0veXU~OwVE7FFQ__',
        },

    ];

    const cardsData = [
        {
            image: Insight1, // Replace with the actual image path
            category: "Crypto News",
            title: "Breaking Bitcoin Purchase, Bolstering Holdings to Over $37B",
            date: "2024-11-26",
            path: 'https://coinmarketcap.com/community/articles/67461f3df3b20e647ba463c3/#sovereign-bitcoin-rush',
            description:
                "MicroStrategy has made headlines with its largest Bitcoin purchase to date, acquiring 55,500 BTC between Nov. 18 and 24 for $5.4 billion.",
        },
        {
            image: Insight2, // Replace with the actual image path
            category: "Crypto News",
            title: "Bitcoin reserve bill introduced in Brazil’s Congress",
            date: "2024-11-27",
            path: 'https://coinmarketcap.com/community/articles/67461f3df3b20e647ba463c3/',
            description:
                "Brazilian legislators in the Chamber of Deputies have proposed allocating 5% of the nation’s $370 billion treasury to a Bitcoin strategic reserve....",
        },

        {
            image: Insight3, // Replace with the actual image path
            category: "Crypto News",
            title: "This week’s crypto opportunities: Buy signals for XRP, LINK, and XYZ",
            date: "2024-11-26",
            path: 'https://crypto.news/this-weeks-crypto-opportunities-buy-signals-for-xrp-link-and-xyz/',
            description:
                "As the crypto market reaches new peaks, several digital assets are exhibiting strong potential for significant growth. Key tokens are poised for breakout...",
        },
    ];

    const TeamMembers = () => {
        return (
            <div className="teamcard-container">
                {teamMembers.map((member, index) => (
                    <div
                        key={index}
                        className={`teamcard ${member.highlighted ? "highlighted" : ""}`}
                    >
                        <img src={member.img} alt={member.name} className="teamcard-image" />
                        <div className='flex flex-col items-center'>
                            <h3 className="teamcard-name">{member.name}</h3>
                            <h4 className="teamcard-title">{member.title}</h4>
                        </div>
                        <div>
                            <p className="text-white text-opacity-50 below-md:text-center">{member.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const DeFiCards = () => {
        return (
            <div className="defi-cards-container below-md:scrollbar-hidden">
                {cardsData.map((card, index) => (
                    <div key={index} className='hover:bg-gradient-to-r from-[#D724FC] via-[#D724FC] to-[#7770FF] p-px rounded-2xl md:w-[30%] min-w-[90%] md:min-w-0 hover:scale-105'>
                        <div className="defi-card cursor-pointer h-full" onClick={() => window.open(card.path, '_blank')}>
                            <img src={card.image} alt={card.title} className="defi-card-image" />
                            <div className="defi-card-content">
                                <span className="defi-card-category">{card.category}</span>
                                <span className="defi-card-title">{card.title}</span>
                                <span className="defi-card-date">{card.date}</span>
                                <p className="defi-card-description">{card.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };


    return (
        <>
            <div className='flex flex-col md:max-w-[1700px] md:mx-auto '>
                <div ref={section1Ref} className='flex px-8 md:px-16 md:flex-row flex-col below-md:gap-8 below-md:py-8'>
                    <div className='flex flex-1 flex-col justify-center gap-8'>
                        <div>
                            <span className='gradient-text'>Empowering the Future of Blockchain</span>
                        </div>
                        <div className="md:w-[80%] below-md:text-center">
                            <span className='vertical-gradient-text'>Innovating Blockchain Solutions for a Decentralized World</span>
                        </div>
                        <div className="md:w-[80%] below-md:text-center">
                            <span className='text-white text-opacity-50'>At Havok Labs, we deliver cutting-edge, full-stack blockchain services, from smart contracts to decentralized applications (dApps), ensuring your business thrives in the evolving blockchain landscape.</span>
                        </div>
                        <div className='flex justify-start gap-8'>
                            <div>
                                <Button
                                    className='explore-btn'
                                    onClick={() => { scrollToSolutions(); }}
                                >
                                    Explore Our Solutions
                                </Button>
                            </div>
                            {/* <div>
                            <Button
                            className='getstarted-btn'
                            onClick={() => {navigate('/login')}}
                            >
                                Get Started Today
                            </Button>
                        </div> */}
                        </div>
                    </div>
                    <div className='flex-1'>
                        <img src={BlockChain} alt='blockchain' />
                    </div>
                </div>
            </div>
            <div className='flex flex-col bg-[#08182F]'>
                {/* Courses */}
                <CoursesSection />
                
                <div className='flex flex-row below-md:p-0 px-16 below-md:flex-col-reverse below-md:gap-12'>
                    <div className='flex-1 below-md:w-[100%] '>
                        <img src={WhyHavocLabs} alt='' />
                    </div>
                    <div className='flex flex-col flex-1 justify-center below-md:items-center items-start gap-4  below-md:p-8'>
                        <div>
                            <span className='singleline-gradient-text'>Why Havok Labs?</span>
                        </div>
                        <div className="below-md:text-center">
                            <span className='text-white text-l text-opacity-50 below-md:text-center'>With years of experience across the entire blockchain ecosystem, Havok Labs offers unparalleled expertise and customized solutions. Whether you're launching a new token, developing a dApp, or integrating blockchain into your existing systems, we have the solution for you.</span>
                        </div>
                    </div>
                </div>

                {/* About */}
                <div ref={section2Ref} className='flex flex-col gap-6 md:mt-32 md:px-16 below-md:mt-12'>
                    <div className='text-center'>
                        <span className='gradient-text'>ABOUT</span>
                    </div>
                    <div className='text-center'>
                        <span className='singleline-gradient-text'>Your Trusted Blockchain Partner</span>
                    </div>

                    <AboutUs />
                </div>

                {/* Service */}
                <div ref={section3Ref} className='flex flex-col gap-6 mt-32 md:px-16'>
                    <div className='text-center'>
                        <span className='gradient-text'>SERVICES</span>
                    </div>
                    <div className='text-center'>
                        <span className='singleline-gradient-text'>Full-Stack Blockchain Solutions</span>
                    </div>
                    {/* <div className='py-16'>
                        <div className="custom-border-left">
                            <div className="top-div-container">
                                <div className="flex flex-col gap-4 below-md:gap-1 items-center max-w-[200px] text-center">
                                    <div className="service-img-div">
                                        <img className="h-[60px] w-[60px] below-md:h-[30px] below-md:w-[30px]" src={blockchain_consulting} alt='service' />
                                    </div>
                                    <div>
                                        <span className="service-title">Blockchain Consulting</span>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4 below-md:gap-1 items-center max-w-[200px] text-center">
                                    <div className="service-img-div">
                                        <img className="h-[60px] w-[60px] below-md:h-[20px] below-md:w-[20px]" src={dApps} alt='service' />
                                    </div>
                                    <div>
                                        <span className="service-title">Decentralized Applications (dApps)</span>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-div-container">
                                <div className="flex flex-col gap-4 below-md:gap-1 items-center max-w-[200px] text-center">
                                    <div className="service-img-div">
                                        <img className="h-[60px] w-[60px] below-md:h-[20px] below-md:w-[20px]" src={contract} alt='service' />
                                    </div>
                                    <div>
                                        <span className="service-title">Smart Contract Development</span>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4 below-md:gap-1 items-center max-w-[200px] text-center">
                                    <div className="service-img-div">
                                        <img className="h-[60px] w-[60px] below-md:h-[20px] below-md:w-[20px]" src={enterprise} alt='service' />
                                    </div>
                                    <div>
                                        <span className="service-title">Enterprise Blockchain Solutions</span>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4 below-md:gap-1 items-center max-w-[200px] text-center">
                                    <div className="service-img-div">
                                        <img className="h-[60px] w-[60px] below-md:h-[20px] below-md:w-[20px]" src={tokendev} alt='service' />
                                    </div>
                                    <div>
                                        <span className="service-title">Token Development</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="custom-border-right">
                            <div className="bottom-div-right-container">
                                <div className="flex flex-col gap-4 below-md:gap-1 items-center max-w-[200px] text-center">
                                    <div className="service-img-div">
                                        <img className="h-[60px] w-[60px] below-md:h-[20px] below-md:w-[20px]" src={FullstackBlockChain} alt='service' />
                                    </div>
                                    <div>
                                        <span className="service-title">Custom Blockchain Development</span>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4 below-md:gap-1 items-center max-w-[200px] text-center">
                                    <div className="service-img-div">
                                        <img className="h-[60px] w-[60px] below-md:h-[20px] below-md:w-[20px]" src={integration} alt='service' />
                                    </div>
                                    <div>
                                        <span className="service-title">Blockchain Integration</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <Services />
                </div>

                {/* Solution */}
                <div ref={section4Ref} className='below-md:hidden flex flex-col gap-6 md:my-40 below-md:my-16 md:px-16'>
                    <div className='text-center'>
                        <span className='singleline-gradient-text'>Solutions for Every Industry</span>
                    </div>
                    <div className='solutions-container'>
                        {
                            solutions.map((solution, index) => {
                                return (
                                    <div className='hover:bg-gradient-to-r from-[#D724FC] via-[#D724FC] to-[#7770FF] p-px rounded-2xl md:w-[30%]'>
                                        <div key={index} className='solutions-card bg-[#0D1437] w-full h-full rounded-2xl'>
                                            <div className='flex flex-row justify-start items-center gap-16'>
                                                <span className='text-white font-semibold text-2xl'>
                                                    {solution.title}
                                                </span>
                                            </div>
                                            <div>
                                                <span className='w-[40%] text-white font-normal text-medium text-opacity-50'>{solution.desc}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div ref={section41Ref} className='md:hidden flex flex-col below-md:gap-3 gap-6 md:my-40 below-md:my-16 md:px-16'>
                    <div className='text-center'>
                        <span className='singleline-gradient-text'>Solutions for Every Industry</span>
                    </div>
                    <div className="flex flex-row flex-1 gap-3 px-3">
                        {
                            solutions.slice(0, 2).map((solution, index) => {
                                return (
                                    <div key={index} className='solutions-card w-[50%]'>
                                        <div className='flex flex-row justify-start items-center gap-16'>
                                            <span className='text-white font-semibold text-xl'>
                                                {solution.title}
                                            </span>
                                        </div>
                                        <div>
                                            <span className='text-white font-normal text-sm text-opacity-50'>{solution.desc}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="px-3 w-[100%]">
                        {
                            solutions.slice(2, 3).map((solution, index) => {
                                return (
                                    <div key={index} style={{ width: '100%' }} className='solutions-card'>
                                        <div className='flex flex-row justify-start items-center gap-16'>
                                            <span className='text-white font-semibold text-xl'>
                                                {solution.title}
                                            </span>
                                        </div>
                                        <div>
                                            <span className='text-white font-normal text-sm text-opacity-50'>{solution.desc}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="flex flex-row flex-1 gap-3 px-3">
                        {
                            solutions.slice(3, 5).map((solution, index) => {
                                return (
                                    <div key={index} className='solutions-card w-[50%]'>
                                        <div className='flex flex-row justify-start items-center gap-16'>
                                            <span className='text-white font-semibold text-xl'>
                                                {solution.title}
                                            </span>
                                        </div>
                                        <div>
                                            <span className='text-white font-normal text-sm text-opacity-50'>{solution.desc}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div ref={section6Ref} className='flex flex-col gap-6 md:mb-36 px-16 below-md:p-8'>
                    <div className='text-center'>
                        <span className='singleline-gradient-text'>Technology Stack</span>
                    </div>

                    <div className='techstack-container gap-6'>
                        {
                            techstacks.map((techstack, index) => {
                                return (
                                    <div key={index} className={`flex ${index === 0 ? 'justify-start' : index === 1 ? 'justify-center' : 'justify-end'} below-md:justify-center`}>
                                        <div key={index} className={`techstack-card`}>
                                            <div className="below-md:flex below-md:justify-end">
                                                <div className='round-gradient-circle md:h-[120px] md:w-[120px] below-md:h-[60px] below-md:w-[60px]'></div>
                                            </div>
                                            <div className='flex flex-col gap-5 below-md:gap-0'>
                                                <div className='flex flex-row justify-start items-center gap-16'>
                                                    <span className='text-white font-semibold md:text-2xl below-md:text-medium'>
                                                        {techstack.title}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className='w-[40%] text-white font-normal below-md:font-small md:text-medium below-md:text-small text-opacity-50'>{techstack.desc}</span>
                                                </div>
                                            </div>
                                            <div className='round-gradient-circle p-2 below-md:flex below-md:justify-start' style={{ width: 'fit-content' }} >
                                                <img className="md:w-[48px] below-md:w-[18px]" src={RightArrowGradient} alt='' />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {/* <div className='flex flex-col gap-6'>
                <div className='text-center'>
                    <span className='gradient-text'>CASE STUDIES</span>
                </div>
                <div className='text-center'>
                    <span className='singleline-gradient-text'>Real-World Impact</span>
                </div>
                <div className='flex flex-row w-[100%] justify-between below-md:overflow-x-auto below-md:scrollbar-hidden below-md:min-w-full below-md:px-8 below-md:gap-8 below-md:hide-scrollbar'>
                    <div className='impact-card'>
                        <img style={{borderRadius:16}}  src={ImpactOne} alt='impact'/>
                        <div className='case-study'>
                            <div className='flex flex-row justify-between items-center'>
                                <div>
                                    <span className='text-white font-bold text-2xl'>CASE STUDY 1</span>
                                </div>
                                <div style={{border:'1px #FFF solid', padding:16, borderRadius:30}}>
                                    <img src={RightSlant} alt='RightSlant' />
                                </div>
                            </div>
                            <div>
                                <span className='text-white'>Revolutionizing Supply Chain Transparency</span>
                            </div>
                        </div>
                    </div>
                    <div className='impact-card'>
                        <img style={{borderRadius:16}}  src={ImpactTwo} alt='impact'/>
                        <div className='case-study'>
                            <div className='flex flex-row justify-between items-center'>
                                <div>
                                    <span className='text-white font-bold text-2xl'>CASE STUDY 2</span>
                                </div>
                                <div style={{border:'1px #FFF solid', padding:16, borderRadius:30}}>
                                    <img src={RightSlant} alt='RightSlant' />
                                </div>
                            </div>
                            <div>
                                <span className='text-white'>Revolutionizing Supply Chain Transparency</span>
                            </div>
                        </div>
                    </div>
                    <div className='impact-card'>
                        <img style={{borderRadius:16}}  src={ImpactOne} alt='impact'/>
                        <div className='case-study'>
                            <div className='flex flex-row justify-between items-center'>
                                <div>
                                    <span className='text-white font-bold text-2xl'>CASE STUDY 3</span>
                                </div>
                                <div style={{border:'1px #FFF solid', padding:16, borderRadius:30}}>
                                    <img src={RightSlant} alt='RightSlant' />
                                </div>
                            </div>
                            <div>
                                <span className='text-white'>Revolutionizing Supply Chain Transparency</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
                {/* <div className='flex flex-col gap-6 below-md:gap-3 mt-[300px] below-md:mt-[50px]'>
                <div className='text-center'>
                    <span className='gradient-text'>MEET OUR TEAM</span>
                </div>
                <div className='text-center'>
                    <span className='singleline-gradient-text'>Team Introduction</span>
                </div>
                <div className='flex justify-center w-[100%]'>
                    <div className='text-center md:w-[60%] below-md:p-8'>
                        <span className='text-white text-opacity-50'>With 10 years of blockchain experience, our team at Havok Labs consists of blockchain pioneers, developers, and strategists passionate about driving the future of decentralized finance. Together, we bring a wealth of expertise to ensure your project’s success.</span>
                    </div>
                </div>
                {
                    <TeamMembers />
                }
            </div> */}
                <div className='flex flex-col gap-6 md:mt-[100px] md:px-16 md:p-8'>
                    <div className='text-center'>
                        <span className='gradient-text below-md:hidden'>OUR PARTNERS & AFFILIATIONS</span>
                    </div>
                    <div className="flex flex-row items-center below-md:flex-col">
                        <div className="flex flex-1">
                            <img className="md:h-[1000px] below-md:h-auto" src={partners} alt='' />
                        </div>
                        <div className="flex flex-1 flex-col md:gap-24 below-md:gap-8 below-md:p-4">
                            <div className="flex flex-col items-start md:py-6 md:px-12 below-md:p-4 partner-card">
                                <span className='singleline-gradient-text'>
                                    Partners
                                </span>
                                <span className="text-white font-normal text-opacity-50">
                                    We partner with leading blockchain platforms and crypto exchanges to provide the most advanced technology and market insights to our clients.
                                </span>
                            </div>
                            <div className="flex flex-col items-start py-6 px-12 below-md:p-8 partner-card">
                                <span className='singleline-gradient-text'>
                                    Affiliations
                                </span>
                                <span className="text-white font-normal text-opacity-50">
                                    Our affiliations with top blockchain networks help boost your project’s credibility and market visibility.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={section5Ref} className='flex flex-col gap-6 below-md:mt-[50px] md:mt-[100px] md:px-16'>
                    <div className='text-center'>
                        <span className='gradient-text'>INSIGHT & BLOG</span>
                    </div>
                    {
                        <DeFiCards />
                    }
                </div>
                <div ref={section7Ref} className='flex flex-col gap-6 mt-[100px] items-center md:px-16 below-md:px-4'>
                    <div className='text-center'>
                        <span className='gradient-text'>CONTACT US</span>
                    </div>
                    <div className='contactus-container'>
                        <div className=" below-md:w-[80%] ">
                            <span className='vertical-gradient-text md:w-[60%]below-md:text-center'>
                                Ready to take your blockchain project to the next level?
                            </span>
                        </div>
                        <div className='flex flex-row below-md:flex-col below-md:items-center'>
                            <div className='flex flex-1 below-md:w-[80%]'>
                                <span className='text-white text-opacity-50 md:w-[60%] below-md:text-center'>Feel free to send us your questions or request a free consultation.</span>
                            </div>
                            <div className='flex flex-1 gap-3 justify-end below-md:flex-col below-md:py-2'>
                                <div>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter your email"
                                        InputProps={{
                                            style: {
                                                borderRadius: "30px", // Rounded corners
                                                color: "#fff", // White text color
                                            },
                                        }}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    borderColor: "rgba(255, 255, 255, 0.5)", // White border
                                                },
                                                "&:hover fieldset": {
                                                    borderColor: "#fff", // Bright white on hover
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "#fff", // White border when focused
                                                },
                                            },
                                            input: {
                                                color: "#fff", // White text inside the input
                                            },
                                            width: "300px", // Adjust width
                                        }}
                                    />
                                </div>
                                <div>
                                    <Button
                                        className='contact-btn below-md:w-full below-md:text-xl'
                                    >
                                        Contact Us
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default LandingPage;