import React, { useEffect, useState } from 'react'
import { Link, ArrowUpRight, ArrowDownRight } from 'lucide-react';
import cashIcon from '../../assets/images/market/casIcon.svg'
import globeIcon from '../../assets/images/market/globe.svg'
import twitterIcon from '../../assets/images/market/twitter.svg'
import fbIcon from '../../assets/images/market/fb.svg'
import starIcon from '../../assets/images/market/star.svg'
import graph from '../../assets/images/market/graph.svg'
import moment from 'moment-timezone';

function LeftBarMarketData({ img, cryptoData = {} }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const StatItem = ({ label, value, percentage, isFull = false }) => (
        <div className='md:border-[1px] md:border-[#272337] px-5 py-2 rounded-xl w-[48%] md:w-full'
            style={{ width: isFull ? '100%' : '', textAlign: isFull ? 'center' : '' }}>
            <p className="text-slate-400 text-sm mb-1">{label}</p>
            <div className="flex items-center gap-2" style={{ justifyContent: isFull ? 'center' : '' }}>
                <span className="text-lg font-medium text-[#3CC8C8]">{value}</span>
                {percentage ? (
                    <span className="text-[#FC3549] text-sm">{percentage}</span>
                ) : (
                    <ArrowUpRight className="text-[#3BC8C7] w-8 h-7 -ml-2" />
                )}
            </div>
        </div>
    );

    const formatTimestamp = (label, data) => {
        const timestamp =
            label === 'All Time High'
                ? data?.highTimestamp
                : data?.lowTimestamp;

        return timestamp // (${moment(timestamp).fromNow()})
            ? `(${moment(timestamp).format('MMM DD, YYYY')})`
            : '';
    };

    const StatHighlight = ({ label, value, isFull = false, data = {} }) => (
        <div className="bg-[#272337] px-3 py-2 rounded flex justify-between items-center text-sm w-[48%] md:w-full"
            style={{ width: isFull ? '100%' : '', textAlign: isFull ? 'center' : '' }}>
            <div className='flex flex-col gap-3'>
                <span className="text-slate-400">
                    {label} {formatTimestamp(label, data)}
                </span>
                <span className='flex gap-2'>
                    <img src={cashIcon} alt='cash' />
                    {value}
                </span>
            </div>

            {/* <select className='bg-transparent border-none focus:outline-none'>
                <option selected>USD</option>
                <option>GBP</option>
            </select> */}

        </div>
    );


    const Rating = ({ cryptoData }) => {
        const { urls, rating = null } = cryptoData;

        return (
            <div className="px-2 mt-2 flex flex-col w-full gap-4 text-[#9197A4] pb-5 border-b-[1px] border-b-[#272337]">
                {/* Website */}
                {urls?.website?.length > 0 && <div className="flex justify-between">
                    <span>Website</span>
                    {urls?.website?.length ? (
                        <a href={urls.website[0]} target="_blank" rel="noopener noreferrer">
                            <img src={globeIcon} alt="Website" className="h-5 w-5" />
                        </a>
                    ) : (
                        <span className="text-[#272337]">N/A</span>
                    )}
                </div>}

                {/* Socials */}
                {(urls?.twitter?.length > 0 || urls?.twitter?.length > 0) &&
                    <div className="flex justify-between">
                        <span>Socials</span>
                        <span className="flex gap-1">
                            {urls?.twitter?.length ? (
                                <a href={urls.twitter[0]} target="_blank" rel="noopener noreferrer">
                                    <img src={twitterIcon} alt="Twitter" className="h-4 w-4" />
                                </a>
                            ) : null}
                            {urls?.facebook?.length ? (
                                <a href={urls.facebook[0]} target="_blank" rel="noopener noreferrer">
                                    <img src={fbIcon} alt="Facebook" className="h-4 w-4" />
                                </a>
                            ) : null}
                            {!urls?.twitter?.length && !urls?.facebook?.length ? (
                                <span className="text-white">N/A</span>
                            ) : null}
                        </span>
                    </div>}

                {/* Ratings */}
                <div className="flex justify-between">
                    <span>Ratings</span>
                    <span className="flex items-center gap-1">
                        {Array.from({ length: Math.floor(rating) }, (_, i) => (
                            <img key={i} src={starIcon} alt="Star" className="h-3.5 w-3.5" />
                        ))}
                        <span className="text-sm text-white">{rating ? rating : '--'}</span>
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div className="bg-[#202F4C] p-4 rounded-2xl">
            <div className='flex justify-between items-center'>
                <div className="flex items-center gap-2 mb-4">
                    <img src={cryptoData?.logo} alt='btc' className='w-8 h-8' />
                    <div>
                        <h2 className="font-semibold">{cryptoData?.name}/{cryptoData?.symbol}</h2>
                        <p className="text-slate-400 text-sm">
                            Today up to <span className={`${cryptoData?.percentChange24h >= 0 ? 'text-[#37CBB0]' : 'text-red-500'}`}>{cryptoData?.percentChange24h >= 0 ? ('+' + cryptoData?.percentChange24h?.toFixed(2) + '%') : ('' + cryptoData?.percentChange24h?.toFixed(2)) + '%'}</span>
                        </p>
                    </div>
                </div>
                <img src={graph} alt='graph' className='h-10' />
            </div>


            <div className="space-y-2 flex flex-wrap md:block gap-2 md:gap-0 justify-between w-full">
                <StatItem label="Price" value={cryptoData.price} />
                <StatItem label="Volume" value={cryptoData.volume} />
                <StatItem label="Market Cap" value={cryptoData.marketCap} percentage="1.54%" />
                <StatItem label="FDV" value={cryptoData.fdv} />
                <StatItem label="Total Supply" value={cryptoData.totalSupply} isFull={isMobile} />

                <div className="space-y-2 flex flex-wrap md:block gap-2 md:gap-0 justify-between w-full">
                    <StatHighlight label="All Time High" value={cryptoData?.performanceStats?.allTimeHigh?.toFixed(3)} isFull={isMobile} data={cryptoData?.performanceStats} />
                    <StatHighlight label="All Time Low" value={cryptoData?.performanceStats?.allTimeLow?.toFixed(3)} isFull={isMobile} data={cryptoData?.performanceStats} />
                </div>

                <Rating cryptoData={cryptoData} />
            </div>
        </div>
    )
}

export default LeftBarMarketData