import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';
import TokenParser from "../../Helpers/tokenParser";

const MessageForm = ({ setIsNewMsg, fetchInquiries }) => {
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [recordsFiltered, setRecordsFiltered] = useState(0);
    const [selectedUser, setSelectedUser] = useState("");
    const [searchValue, setSearchValue] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const dropdownRef = useRef(null);
    const searchRef = useRef(null);

    let token = Cookies.get('token');
    let details = TokenParser(token);

    let headers = {
        Authorization: `Bearer ${token}`,
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
                searchRef.current && !searchRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        if (details?.payload?.role === 'admin') {
            // Fetch the list of users
            const fetchUsers = async () => {
                try {
                    axios.get(`${process.env.REACT_APP_BACKEND}users${searchValue ? `?searchValue=${searchValue}` : ''}`, { headers: headers })
                        .then((apiResponse) => {
                            setUsers(apiResponse.data.users);
                            setRecordsFiltered(apiResponse.data.recordsFiltered);
                            setLoading(false);
                        })
                        .catch((apiError) => {
                            setLoading(false);
                        })
                } catch (error) {
                    toast.error("Error fetching users.", { theme: "dark" });
                }
            };
            fetchUsers();
        }
    }, [details?.payload?.role, searchValue]);


    const validateForm = () => {
        const newErrors = {};
        if (
            details?.payload?.role === 'admin' &&
            (!searchValue || !users?.some((e) => e?.userName === searchValue))
        ) {
            newErrors.selectedUser = "Please select a user.";
        }
        if (!subject.trim()) newErrors.subject = "Subject is required.";
        if (!message.trim()) newErrors.message = "Message is required.";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        setLoading(true);
        try {
            let api = `${process.env.REACT_APP_BACKEND}send_message`
            let headers = {
                Authorization: `Bearer ${token}`
            }
            let payload = {
                subject,
                message,
                ...(details?.payload?.role === 'admin' && { recipientEmail: selectedUser?.emailId })
            }

            const response = await axios.post(
                api, payload, { headers: headers }
            );

            // if success
            if (response?.data) {
                toast.success("Message has been sent successfully.", { theme: 'dark' });
                await fetchInquiries()
                reset()
            } else {
                toast.error("Failed to send message", { theme: 'dark' });
            }

        } catch (error) {
            toast.error("Failed to send message", { theme: 'dark' });
        } finally {
            setLoading(false);
        }
    };

    // Reset form state and errors
    const reset = () => {
        setSubject("");
        setMessage("");
        setSearchValue("");
        setSelectedUser(null);
        setErrors({});
        setIsNewMsg(false);
        setIsDropdownOpen(false);
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);
        setSearchValue(user.userName);
        setIsDropdownOpen(false);
        setErrors({ ...errors, selectedUser: '' })
    };

    console.log(searchValue, errors, 'search')
    return (
        <div className="h-screen flex items-center justify-center">
            <form
                onSubmit={handleSubmit}
                className="w-[90%] mx-auto h-full"
            >
                {details?.payload?.role === 'admin' && (
                    <div className={`mb-4 bg-[#2B3D5F] flex flex-col border p-3 rounded-xl relative ${errors.selectedUser ? "border-red-500" : "border-[#2B3D5F]"
                        }`}>
                        <label htmlFor="user-search" className="block text-[#E0E0E0] font-normal">
                            Search User
                        </label>
                        <input
                            ref={searchRef}
                            id="user-search"
                            type="text"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onFocus={() => {
                                if (users.length > 0) setIsDropdownOpen(true);
                            }}
                            className="bg-[#2B3D5F] border-none outline-none focus:bg-[#2B3D5F] text-white font-semibold w-full p-2 mt-1 rounded-md focus:outline-none"
                            placeholder="Search users..."
                            autoComplete="off"
                        />

                        {isDropdownOpen && users.length > 0 && (
                            <div
                                ref={dropdownRef}
                                className="absolute top-full left-0 right-0 mt-1 bg-[#2B3D5F] border border-[#3D4E70] rounded-md shadow-lg max-h-48 overflow-y-auto z-50 scrollbar-none"
                            >
                                {users.map((user) => (
                                    <div
                                        key={user.emailId}
                                        className="p-2 hover:bg-[#3D4E70] cursor-pointer text-white"
                                        onClick={() => handleUserSelect(user)}
                                    >
                                        <div className="font-semibold">{user.userName}</div>
                                        <div className="text-sm text-gray-300">{user.emailId}</div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {errors.selectedUser && (
                            <p className="text-red-400 text-sm mt-1">{errors.selectedUser}</p>
                        )}
                    </div>
                )}

                <div className={`mb-4 bg-[#2B3D5F] border p-3 rounded-xl  ${errors.subject ? "border-red-500" : "border-[#2B3D5F]"
                    } `}>
                    <label htmlFor="subject" className="block text-[#E0E0E0] font-normal">
                        Subject
                    </label>
                    <input
                        id="subject"
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        className={`bg-[#2B3D5F] border-none outline-none focus:bg-[#2B3D5F] text-white font-semibold w-full p-2 mt-1 rounded-md focus:outline-none`}
                    />
                    {errors.subject && (
                        <p className="text-red-400 text-sm mt-1">{errors.subject}</p>
                    )}
                </div>
                <div className={`mb-4 bg-[#2B3D5F] border p-3 rounded-xl  ${errors.message ? "border-red-500" : "border-[#26DDB9]"
                    } `}>
                    <label htmlFor="message" className="block text-[#E0E0E0] font-normal">
                        Message
                    </label>
                    <textarea
                        id="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className={`md:h-[300px] bg-transparent border-none outline-none text-white w-full p-2 mt-1 border ${errors.message ? "border-red-500" : "border-gray-300"
                            } rounded-md focus:outline-none`}
                    />
                    {errors.message && (
                        <p className="text-red-500 text-sm mt-1">{errors.message}</p>
                    )}
                </div>
                <div className="flex items-center justify-center gap-5 text-md md:text-lg">
                    <button
                        type="submit"
                        disabled={loading}
                        className={`w-fit min-w-[100px] md:min-w-[200px] flex items-center justify-center p-2 md:p-3 text-white bg-gradient-to-l from-[#0EEEA8] to-[#7770FF] rounded-full ${loading ? "opacity-50 cursor-not-allowed" : ""
                            }`}
                    >
                        {loading ? (
                            <span className="loader animate-spin w-5 h-5 border-2 border-white border-t-transparent rounded-full"></span>
                        ) : (
                            "Send"
                        )}
                    </button>

                    <button
                        type="button"
                        onClick={reset}
                        className={`w-fit min-w-[100px] md:min-w-[200px] flex items-center justify-center p-2 md:p-3 text-white bg-[#37445E] rounded-full`}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default MessageForm