import React from 'react';

const SectionLoader = () => {
    return (
        <div className="relative w-full h-full">
            {/* Loader Overlay */}
            <div className="absolute top-0 left-0 w-full h-full bg-opacity-50 backdrop-blur-md flex justify-center items-center z-10">
                <div className="relative">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-purple-500 border-solid"></div>
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-sm font-medium">
                        Loading...
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionLoader;
