import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi'; // Importing an icon library
import { MdArrowDropDown } from 'react-icons/md';
import CommonDropdown from '../../Components/Common/CommonDropdown';

const NewsSearchSort = ({ searchTerm, setSearchTerm }) => {
  const [sortOpen, setSortOpen] = useState(false);
  const [sortOption, setSortOption] = useState('Sort By');
  const dropdownOptions = [
    { value: 'new', label: 'Newest' },
    { value: 'old', label: 'Oldest' },
    { value: 'populer', label: 'Populer' },
  ];

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortToggle = () => {
    setSortOpen(!sortOpen);
  };

  const handleSortOptionClick = (option) => {
    setSortOption(option);
    setSortOpen(false);
  };

  return (
    <div className="flex flex-col md:flex-row items-center gap-5 bg-dark-blue p-4 rounded-lg">
      {/* Search Button */}
      <div className="flex items-center bg-opacity-20 bg-white rounded-full px-4 py-2 cursor-pointer text-white">
        <FiSearch className="mr-2" />
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          className="bg-transparent focus:outline-none text-white placeholder-gray-300"
        />
      </div>

      {/* Sort Button */}
      <CommonDropdown
        options={dropdownOptions}
        selectedValue={sortOption}
        onChange={handleSortOptionClick}
        minWidth={150}
        classes='bg-opacity-20 bg-white'
      />
      {/* <div className="relative">
        <button 
          onClick={handleSortToggle}
          className="flex items-center bg-opacity-20 bg-white rounded-full px-4 py-2 cursor-pointer text-white"
        >
          <span>{sortOption}</span>
          <MdArrowDropDown className="ml-2" />
        </button>

        {sortOpen && (
          <div className="absolute mt-2 w-full bg-dark-blue rounded-lg shadow-lg">
            <ul className="text-white">
              <li 
                onClick={() => handleSortOptionClick('Newest')}
                className="px-4 py-2 hover:bg-opacity-20 hover:bg-white cursor-pointer"
              >
                Newest
              </li>
              <li 
                onClick={() => handleSortOptionClick('Oldest')}
                className="px-4 py-2 hover:bg-opacity-20 hover:bg-white cursor-pointer"
              >
                Oldest
              </li>
              <li 
                onClick={() => handleSortOptionClick('Popular')}
                className="px-4 py-2 hover:bg-opacity-20 hover:bg-white cursor-pointer"
              >
                Popular
              </li>
            </ul>
          </div>
        )}
      </div> */}
    </div>
  );
};


export default NewsSearchSort