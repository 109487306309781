import NewsFeed from "./Market/NewsFeed";
import React, { useEffect, useState } from 'react';
// news feed images
import img1 from '../assets/images/market/1.png'
import img2 from '../assets/images/market/2.png'
import img3 from '../assets/images/market/3.png'
import { getFirstName, getUserName } from "../services/common.utils";
import { useNavigate } from "react-router-dom";
import { articles } from "./Market/MarketDetails";
import NewsSearchSort from "./Market/NewsSearchSort";
import axios from "axios";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";

const News = () => {
    let token = Cookies.get('token');
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('Bitcoin');
    const [articlesData, setArticlesData] = useState([])
    const [loading, setLoading] = useState(false)
    const [totalRecordsArticle, setTotalRecordsArticle] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const recordsPerPage = 20;

    let start = (currentPage - 1) * recordsPerPage + 1;

    const fetchArticleData = async () => {
        setLoading(true);
        try {
            let headers = {
                Authorization: `Bearer ${token}`
            }
            const response = await axios.get(process.env.REACT_APP_BACKEND + "news?words=" + (searchTerm ? searchTerm : 'Bitcoin') + '&start=' + start + '&length=' + recordsPerPage, { headers: headers });
            let res = response?.data?.articles
            let converted_data = res?.results ? res?.results : [];
            setArticlesData(converted_data)
            setTotalRecordsArticle(res?.totalResults)
        } catch (error) {
            toast.error("Failed to fetch articles", {
                theme: "dark",
            });
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchArticleData()
    }, [searchTerm, start])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    // render
    return (
        <div className="relative md:space-y-5">
            <p className='font-semibold text-2xl capitalize text-white text-center md:text-start'>
                Hello <span className='text-[#3CC8C8]'>{getUserName()}</span>
            </p>
            <div className='md:flex justify-end md:absolute right-0 top-[40px] z-5'>
                <NewsSearchSort searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            </div>
            <NewsFeed
                title={'News'}
                articles={articlesData}
                loading={loading}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                recordsFiltered={totalRecordsArticle}
                recordsPerPage={recordsPerPage} />
        </div>
    )
}

export default News;