import React, { useState } from 'react';

const TimePeriodSelector = ({ selected = '1D', setSelected }) => {

  const periods = [
    {value: '1d', label: '1D'},
    {value: '7d', label: '7D'},
    {value: '30d', label: '1M'},
    {value: '90d', label: '3M'},
    {value: '365d', label: '1Y'},
  ]

  return (
    <div className="bg-[#2B3D5F] p-1 rounded-lg inline-flex gap-1 w-fit">
      {periods?.map((period) => (
        <button
          key={period}
          onClick={() => setSelected(period?.value)}
          className={`
            px-2 py-1.5 
            text-sm 
            rounded-md 
            transition-colors 
            duration-200
            uppercase
            ${selected?.toLocaleLowerCase() === period?.value?.toLowerCase()
              ? 'bg-[#1E2C49] text-white rounded-full'
              : 'text-[#FFFFFF] hover:text-gray-300'
            }
          `}
        >
          {period.label }
        </button>
      ))}
    </div>
  );
};

export default TimePeriodSelector;