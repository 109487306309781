import { ChevronLeft, Menu, PlusIcon, Section } from 'lucide-react';
import React, { useEffect, useState } from 'react';

import { Close } from '@mui/icons-material';
import MessageCard from './MessageCard';
import MessageDetails from './MessageDetails';
import MessageMenu from './MessageMenu';
import MessageForm from './MessageForm';
import SectionLoader from '../../Components/Common/SectionLoader';

const EmailInbox = ({ messages = {}, activeMessage, setActiveMessage, searchValue, setSearchValue, fetchInquiries }) => {
    const [activeView, setActiveView] = useState('inbox');
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [isNewMsg, setIsNewMsg] = useState(false)
    const [isOpenResponse, setIsOpenResponse] = useState(false)
    const filterMessages = activeView === 'inbox' ? messages?.inbox : messages?.sent

    // Function to handle message click and show details
    const handleMessageClick = (message) => {
        setActiveMessage(message);
        setIsOpenResponse(false)
        if (isMobileView) {
            setActiveView('messageDetails')
        }
    };

    // Function to go back to message list
    const handleBackButton = () => {
        setActiveMessage(null);
        setActiveView('inbox');
    };

    // Function to toggle menu view
    const toggleMenu = (param = null) => {
        setActiveView(param ? param : activeView === 'inbox' ? 'menu' : 'inbox');
        if (activeView !== param) {
            setActiveMessage(null)
        }
        if (param) {
            fetchInquiries()
        }
        setIsNewMsg(false)
    };

    // Function to handle window resize
    const handleResize = () => {
        setIsMobileView(window.innerWidth <= 768);
    };

    // const render Message list or Details
    const renderMessage = () => {
        return (
            isMobileView ?
                <>
                    {(activeView === 'inbox' || activeView === 'sent') && (
                        <div className="flex-grow overflow-y-auto">
                            {filterMessages.map((message) => (
                                <MessageCard
                                    message={message}
                                    handleMessageClick={handleMessageClick}
                                    activeMessage={activeMessage}
                                />
                            ))}
                        </div>
                    )}

                    {activeView === 'messageDetails' && (
                        <div className="flex flex-col flex-grow msg">
                            <MessageDetails message={activeMessage} isOpenResponse={isOpenResponse}
                                setIsOpenResponse={setIsOpenResponse} fetchInquiries={fetchInquiries} />
                        </div>
                    )}

                    {activeView === 'menu' && (
                        <div className="p-4">
                            <MessageMenu toggleMenu={toggleMenu} activeView={activeView} setIsNewMsg={setIsNewMsg}
                                searchValue={searchValue} setSearchValue={setSearchValue} />
                        </div>
                    )}
                </> :
                <>
                    {/* Message List */}
                    <div
                        className={`${activeMessage ? 'col-span-2 border-r-2 border-r-[#253352] ' : 'col-span-5'
                            } overflow-y-auto scrollbar-thin scrollbar-thumb-[#374151] scrollbar-track-transparent overflow-x-auto whitespace-nowrap`}
                    >
                        {filterMessages?.map((message) => (
                            <div key={message.id}>
                                <MessageCard
                                    message={message}
                                    handleMessageClick={handleMessageClick}
                                    activeMessage={activeMessage}
                                />
                            </div>
                        ))}
                    </div>

                    {/* Message Details */}
                    {activeMessage && (
                        <div className="col-span-3 p-4 flex flex-col overflow-y-auto scrollbar-thin msg">
                            <MessageDetails message={activeMessage} isOpenResponse={isOpenResponse}
                                setIsOpenResponse={setIsOpenResponse} fetchInquiries={fetchInquiries} />
                        </div>
                    )}
                </>
        )
    }

    // event listener for window resize
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // back action
    const handleBackAction = () => {
        if (activeView === 'sent') {
            setActiveView('inbox')
        } else if (activeView === 'messageDetails') {
            setActiveView('inbox')
        } else if (activeView === 'menu') {
            setActiveView('inbox')
        }
    }

    useEffect(() => {
        if (isMobileView) {
            if (isNewMsg) {
                setActiveView('newMessage')
            } else {
                setActiveView('inbox')
            }
        }
    }, [isMobileView, isNewMsg])
    console.log(messages)
    // const filterMessages = activeView === 'inbox' ? (messages.inbox?.length > 0 ? messages.inbox : []) : (messages?.sent?.length > 0 ? messages?.sent : [])
    // const filterMessages = messages?.length > 0 ? activeView === 'inbox' ? messages?.filter(e => e.type === 'received') : messages?.filter(e => e.type === 'sent') : []

    return (
        <div className="h-screen md:max-h-[85vh] md:overflow-y-hidden" onResize={handleResize}>
            {isMobileView ? (
                // Mobile view
                <div className="flex flex-col h-full">
                    {(activeView === 'messageDetails') &&
                        <button type='button' onClick={handleBackAction} className='flex text-[#35CCCD] justify-start gap-3 items-center'>
                            <ChevronLeft />
                            Back
                        </button>}

                    {
                        isNewMsg ?
                            <div
                                className={`col-span-5 overflow-y-auto scrollbar-thin scrollbar-thumb-[#374151] scrollbar-track-transparent overflow-x-auto whitespace-nowrap`}
                            >
                                <MessageForm setIsNewMsg={setIsNewMsg} fetchInquiries={fetchInquiries} />
                            </div>
                            : renderMessage()
                    }

                    <button
                        className="absolute bottom-4 right-3 text-white font-bold p-2 rounded-full bg-[#24DEB9]"
                        onClick={() => toggleMenu(activeView === 'menu' ? 'inbox' : 'menu')}
                    >
                        {activeView === 'menu' ? <Close /> : <Menu />}
                    </button>
                </div>
            ) : (
                // Desktop view
                <div className="grid grid-cols-6 h-full">
                    {/* Sidebar */}
                    <div className="col-span-1 border-r-2 border-r-[#253352] overflow-y-auto flex flex-col gap-5">
                        <MessageMenu toggleMenu={toggleMenu} activeView={activeView} setIsNewMsg={setIsNewMsg}
                            searchValue={searchValue} setSearchValue={setSearchValue} />
                    </div>
                    {
                        isNewMsg ?
                            <div
                                className={`col-span-5 overflow-y-auto scrollbar-thin scrollbar-thumb-[#374151] scrollbar-track-transparent overflow-x-auto whitespace-nowrap`}
                            >
                                <MessageForm setIsNewMsg={setIsNewMsg} fetchInquiries={fetchInquiries} />
                            </div>
                            : renderMessage()
                    }

                </div>
            )}
        </div>
    );
};

export default EmailInbox;