import React, { useEffect, useState } from 'react';
import { getFirstName } from '../../services/common.utils';
import { useNavigate } from 'react-router-dom';
import { Send, X } from 'lucide-react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const InquiryResponse = ({ data, setIsOpenResponse, getInquiries, fetchDetails }) => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        initialBalance: '',
        adminResponse: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Set initial data from inquiry
    useEffect(() => {
        if (data) {
            setFormData({
                ...data,
                fullName: `${data.userFirstName || ''} ${data.userLastName || ''}`.trim(),
                email: data.email || data.userEmail || '',
                initialBalance: data.clientMessage?.match(/\d+/)?.[0] || '',
                adminResponse: data.replyMessage || '',
            });
        }
    }, [data]);


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData?.response === 'resolved') {
            toast.info('Inquiry already resolved', { theme: 'dark' })
            return
        }

        setLoading(true)

        // Validation (optional, based on formData)
        if (!formData.adminResponse) {
            setError('Please enter a response');
            setLoading(false);
            return;
        }


        // Reset error and set success message
        try {
            const requestBody = {
                // subject: data?.subject,
                message: formData?.adminResponse,
                messageId: data?.messageId ? data?.messageId : data?.id
            };

            let token = Cookies.get('token');

            let headers = {
                Authorization: `Bearer ${token}`
            }
            // API request to reply to the inquiry
            const response = await axios.post(process.env.REACT_APP_BACKEND + 'send_message', requestBody, {
                headers: headers
            });

            if (response?.data) {
                const responseData = await response.data;
                setSuccess('Your inquiry has been replied successfully!');
                setError(''); // Reset error message
                await getInquiries()
                setIsOpenResponse(false)
                await fetchDetails()
            } else {
                const errorData = await response.data;
                setError(errorData.message || 'Failed to reply to the inquiry');
                setSuccess('');
            }
        } catch (error) {
            setError(error?.response?.data?.message || 'Something went wrong. Please try again later');
            setSuccess('');
        } finally {
            setLoading(false);
        }
    };

    const handleChangeStatus = async () => {
        try {
            const requestBody = {
                // subject: data?.subject,
                message: formData?.adminResponse,
                messageId: data?.messageId ? data?.messageId : data?.id,
                status: 'close'
            };

            let token = Cookies.get('token');

            let headers = {
                Authorization: `Bearer ${token}`
            }
            // API request to reply to the inquiry
            const response = await axios.post(process.env.REACT_APP_BACKEND + 'send_message', requestBody, {
                headers: headers
            });

            if (response?.data) {
                const responseData = await response.data;
                if (responseData) {
                    setSuccess('Your inquiry has been replied successfully!');
                    setError(''); // Reset error message
                    await getInquiries()
                    await fetchDetails()
                    setIsOpenResponse(false)
                } else {
                    setSuccess('');
                    setError(responseData?.message || 'Failed to close the thread');
                }
            } else {
                const errorData = await response.data;
                setError(errorData.message || 'Failed to reply to the inquiry');
                setSuccess('');
            }
        } catch (error) {
            setError(error?.response?.data?.message || 'Something went wrong. Please try again later');
            setSuccess('');
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="h-full text-white md:p-6">
            {/* Header */}
            {/* <h1 className="text-4xl font-light mb-12 text-center md:text-start">
                Hello <span className="text-[#3CC8C8] capitalize">{getFirstName()}</span>
            </h1> */}
            <div className="max-w-7xl mx-auto">
                <div className="text-white">
                    <div className='flex justify-between items-center'>
                        <h2 className="text-2xl font-bold mb-4">Respond</h2>
                        <span onClick={handleChangeStatus} className={`${formData?.threadClosed === true ? 'bg-[#2B3C5E] text-opacity-50' : 'bg-[#24DEB9] cursor-pointer'} text-white rounded-full px-8 py-1`}>
                            {formData?.threadClosed === true ? 'Closed' : 'Open'}
                        </span>
                    </div>
                    <div className='mt-5 w-full flex flex-col gap-5 justify-between'>
                        <div className='md:w-1/1 flex flex-col md:flex-row justify-between gap-3'>
                            <div className="md:mt-[0rem]">
                                <div className="details-header  hidden md:block" style={{ color: ' #3CC8C8' }}>
                                    Full Name
                                </div>
                                <div className="details-value capitalize" style={{ color: '#3CC8C8' }}>
                                    {formData?.sender || 'N/A'}
                                </div>
                            </div>
                            <div>
                                <div className="details-header">
                                    Email Address
                                </div>
                                <div className="details-value text-md md:text-lg break-words">
                                    {formData?.email || 'N/A'}
                                </div>
                            </div>
                            <div>
                                <div className="details-header">
                                    Phone Number
                                </div>
                                <div className="details-value text-md md:text-lg">
                                    {formData?.userPhoneNumber || 'N/A'}
                                </div>
                            </div>
                        </div>
                        <div className='md:w-1/1'>
                            <form onSubmit={handleSubmit} className="mt-8 space-y-4">
                                <div className='bg-[#2B3D5F] md:p-7 p-5 rounded-3xl'>
                                    {data?.body}
                                </div>
                                <div>
                                    <textarea
                                        id="adminResponse"
                                        name="adminResponse"
                                        value={formData.adminResponse}
                                        onChange={handleChange}
                                        className="focus:outline-none outline-none bg-transparent border-2 border-[#3CC8C8] rounded-3xl text-white md:p-7 p-5 w-full h-32"
                                    />
                                </div>
                                <div className='relative flex justify-end flex-row gap-3'>
                                    <div className='absolute left-2'>
                                        {error && <div className="text-red-500 mt-4">{error}</div>}
                                        {success && <div className="text-green-500 mt-4">{success}</div>}
                                    </div>
                                    <button className="bg-[#2B3C5E] text-opacity-50 text-white rounded-full px-8 py-3 md:py-1 w-full md:w-fit"
                                        onClick={() => setIsOpenResponse(false)}>
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        isDisabled={formData?.response === 'resolved'}
                                        style={{ background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)' }}
                                        // onClick={handleSubmit}
                                        className="w-full md:w-fit px-12 float-right text-white rounded-full py-4 font-medium hover:opacity-90 transition-opacity flex items-center justify-center gap-2"
                                    >
                                        {loading ? (
                                            <>
                                                <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                                                <span>Sending ...</span>
                                            </>
                                        ) : (
                                            <>
                                                <span>Send</span>
                                            </>
                                        )}
                                    </button>
                                </div>


                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default InquiryResponse