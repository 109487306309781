import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import qa1 from '../../assets/images/qa1.svg'
import qa2 from '../../assets/images/qa2.svg'
import qa3 from '../../assets/images/qa3.svg'
import StatsCard from './Statscard';
import ProfitChart from './ProfitChart';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import CommonDropdown from '../Common/CommonDropdown';

const QuickAnalytics = ({ stats, analyticsStat, handleDropdownStatChange }) => {
    const location = useLocation();

    const [chartSelectedValue, setChartSelectedValue] = useState("today");
    // const [chartData, setChartData] = useState([])

    const handleChartDropdownChange = (value) => {
        setChartSelectedValue(value);
    };
    const chartData = [
        { month: 'Jan', value: 25000 },
        { month: 'Feb', value: 18000 },
        { month: 'Mar', value: 22000 },
        { month: 'Apr', value: 20000 },
        { month: 'May', value: 30000 },
        { month: 'Jun', value: 25000 },
        { month: 'Jul', value: 35000 },
        { month: 'Aug', value: 32000 },
        { month: 'Sep', value: 40000 },
        { month: 'Oct', value: 38000 },
    ];

    const dropdownOptions = [
        { value: 'last transaction', label: 'Last Transaction' },
        { value: 'today', label: 'Today' },
        { value: '1 week', label: 'Last 7 Days' },
        { value: '1 month', label: 'Last 30 Days' },
        { value: 'all time', label: 'All Time' },
    ];

    useEffect(() => {
        const fetchData = async () => {
            const pathSegments = location.pathname.split('/');
            const userId = pathSegments[pathSegments.length - 1];
            try {
                let token = Cookies.get('token');
                let headers = {
                    Authorization: `Bearer ${token}`,
                };
                if (userId && userId !== '') {
                    headers["userId"] = userId;
                }
                const response = await axios.get(process.env.REACT_APP_BACKEND +
                    `stats?timeframe=${chartSelectedValue}`, { headers: headers });
                const stats = response.data.stats;

                const chartData_modified = {
                    labels: stats.map(stat => stat.date),
                    datasets: stats.map(stat => stat.balance),
                };
                console.log(chartData_modified)
                // setChartData(chartData_modified);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        // fetchData();
    }, [chartData.datasets, chartSelectedValue, location.pathname]);

    // Render
    return (
        <div className='space-y-5'>
            <div className='flex flex-col md:flex-row gap-5 items-center w-full md:bg-[#212F4C] md:p-5 md:rounded-2xl'>
                {/* Top Stats Grid */}
                <div className="flex flex-col gap-4 md:w-[30%] w-full">
                    <p className='text-white font-medium text-xl hidden md:block'>Quick Analytics</p>
                    {/* Total Trades div */}
                    <div className="max-w-md space-y-2">
                        {stats?.map((card, index) => (
                            <StatsCard
                                key={index}
                                title={card.title}
                                value={card.value}
                                change={card.change}
                                bgColor={card.bgColor}
                                textColor={card.textColor}
                                titleColor={card.titleColor}
                                onClick={card.onClick}
                                isActive={card.isActive}
                                hasFilter={card.hasFilter || false}
                                days={card.days}
                                dropdownValue={card.dropdownValue}
                                selectedValue={card.selectedValue}
                                handleDropdownChange={handleDropdownStatChange}
                                id={card.id}
                            />
                        ))}
                    </div>

                </div>

                {/* Chart Section */}
                <div className="p-4 rounded-lg md:w-[70%]  w-full h-full">
                    <div className='text-end text-xs'>
                        {/* <select
                            value={chartSelectedValue}
                            onChange={handleChartDropdownChange}
                            className='bg-[#212C45] outline-none text-white p-2 px-4 rounded-xl border-px border-[#212C45]'>
                            <option value="Last Transaction" selected>Recent Transcations</option>
                            <option value="today">Today</option>
                            <option value="1 week">This week</option>
                            <option value="1 month">This month</option>
                        </select> */}
                        <CommonDropdown
                            options={dropdownOptions}
                            selectedValue={chartSelectedValue}
                            onChange={handleChartDropdownChange}
                        />
                    </div>
                    <ProfitChart data={chartData} height='h-[400px] -ms-10 md:ms-0' />
                </div>
            </div>

            {/* Bottom Stats */}
            <div className="text-lg flex flex-col space-y-2 md:flex-row md:px-0 md:py-5 rounded-2xl text-white font-normal md:justify-around"
                style={{
                    background: window.innerWidth > 1200 ? `linear-gradient(272.44deg, #0EEEA8 4.31%, #7770FF 129.99%),
                                    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))` : 'transparent'
                }}>
                <div className="flex items-center space-x-4 p-3 rounded-2xl md:rounded-none md:p-0"
                    style={{
                        background: window.innerWidth < 1200 ? `linear-gradient(272.44deg, #0EEEA8 4.31%, #7770FF 129.99%),
                                    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))` : 'transparent'
                    }}
                >
                    <img src={qa1} alt="qa1" className="h-14" />
                    <div>
                        <div>Total Users</div>
                        <div className="text-2xl font-bold text-white flex items-center gap-2">
                            {analyticsStat.totalUsers.count}
                            {/* <span className="text-[#7E6CFF] text-sm font-medium bg-white rounded-2xl px-3 py-1">
                                +{analyticsStat.totalUsers.growth}%
                            </span> */}
                        </div>
                    </div>
                </div>

                {/* Pending Inquiries */}
                <div className="flex items-center space-x-4 p-3 rounded-2xl md:rounded-none md:p-0"
                    style={{
                        background: window.innerWidth < 1200 ? `linear-gradient(272.44deg, #0EEEA8 4.31%, #7770FF 129.99%),
                                    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))` : 'transparent'
                    }}>
                    <img src={qa2} alt="qa2" className="h-14" />
                    <div>
                        <div>Pending Inquiries</div>
                        <div className="text-4xl font-bold text-white flex items-center gap-2">
                            {analyticsStat.pendingInquiries.count}
                            {/* <span className="text-[#7E6CFF] text-sm font-medium bg-white rounded-2xl px-3 py-1">
                                +{analyticsStat.pendingInquiries.growth}%
                            </span> */}
                        </div>
                    </div>
                </div>

                {/* Trading Users */}
                <div className="flex items-center space-x-4 p-3 rounded-2xl md:rounded-none md:p-0"
                    style={{
                        background: window.innerWidth < 1200 ? `linear-gradient(272.44deg, #0EEEA8 4.31%, #7770FF 129.99%),
                                    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))` : 'transparent'
                    }}>
                    <img src={qa3} alt="qa3" className="h-14" />
                    <div>
                        <div>Trading Users</div>
                        <div className="text-2xl font-bold text-white">
                            {analyticsStat.tradingUsers.count}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuickAnalytics;