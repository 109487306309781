import React, { useEffect, useState } from 'react';
import StatsCard from './Statscard';
import ProfitChart from './ProfitChart';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import TradeStatUser from '../ActiveTrades/TradeStatUser';
import ProfitChartUser from './ProfitChartUser';
import moment from 'moment';
import { mergeDataByDay } from '../../services/common.utils';

const QuickAnalyticsUser = ({ stats, analyticsStat, handleDropdownStatChange, walletData }) => {
    const location = useLocation();
    let mergedData = mergeDataByDay(walletData)
    let sortedWalletData = mergedData?.sort((a, b) => {
        const dateA = moment(a.updatedOn, "MM-DD-YYYYTHH:mm:ss A").toDate();
        const dateB = moment(b.updatedOn, "MM-DD-YYYYTHH:mm:ss A").toDate();
        return dateA - dateB; 
    });
    console.log(walletData, sortedWalletData)
    const [chartSelectedValue, setChartSelectedValue] = useState("1 month");

    const handleChartDropdownChange = (event) => {
        setChartSelectedValue(event.target.value);
    };
    const chartData = [
        { month: 'Jan', value: 25000 },
        { month: 'Feb', value: 18000 },
        { month: 'Mar', value: 22000 },
        { month: 'Apr', value: 20000 },
        { month: 'May', value: 30000 },
        { month: 'Jun', value: 25000 },
        { month: 'Jul', value: 35000 },
        { month: 'Aug', value: 32000 },
        { month: 'Sep', value: 40000 },
        { month: 'Oct', value: 38000 },
    ];

    useEffect(() => {
        const fetchData = async () => {
            const pathSegments = location.pathname.split('/');
            const userId = pathSegments[pathSegments.length - 1];
            try {
                let token = Cookies.get('token');
                let headers = {
                    Authorization: `Bearer ${token}`,
                };
                if (userId && userId !== '') {
                    headers["userId"] = userId;
                }
                const response = await axios.get(process.env.REACT_APP_BACKEND +
                    `stats?timeframe=${chartSelectedValue}`, { headers: headers });
                const stats = response.data.stats;

                const chartData_modified = {
                    labels: stats.map(stat => stat.date),
                    datasets: stats.map(stat => stat.balance),
                };
                // console.log(chartData_modified)
                // setChartData(chartData_modified);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        // fetchData();
    }, [chartData.datasets, chartSelectedValue, location.pathname]);

    // Render
    return (
        <div className='space-y-5'>
            <div className='flex flex-col md:flex-row gap-5 items-center w-full md:bg-[#212F4C] md:p-5 md:rounded-2xl'>
                {/* Top Stats Grid */}
                <div className="flex flex-col gap-4 md:w-[30%] w-full">
                    <p className='text-white font-medium text-xl hidden md:block'>Quick Analytics</p>
                    {/* Total Trades div */}
                    <div className="max-w-md space-y-2">
                        {stats?.map((card, index) => (
                            <StatsCard
                                isUser={true}
                                key={index}
                                title={card.title}
                                value={card.value}
                                change={card.change}
                                bgColor={card.bgColor}
                                textColor={card.textColor}
                                titleColor={card.titleColor}
                                onClick={card.onClick}
                                isActive={card.isActive}
                                hasFilter={card.hasFilter || false}
                                dropdownValue={card.dropdownValue}
                                selectedValue={card.selectedValue}
                                days={card.days}
                                handleDropdownChange={handleDropdownStatChange}
                                id={card.id}
                            />
                        ))}
                    </div>

                </div>

                {/* Chart Section */}
                <div className="p-4 rounded-lg md:w-[70%]  w-full h-full">
                    <div className='text-end'>
                        <select
                            value={chartSelectedValue}
                            disabled
                            onChange={handleChartDropdownChange}
                            className='bg-[#212C45] outline-none text-white p-2 px-4 rounded-full border-px border-[#212C45] w-[150px]'>
                            <option value="Last Transaction" selected>Recent</option>
                            <option value="today">Today</option>
                            <option value="1 week">This week</option>
                            <option value="1 month">This month</option>
                        </select>
                    </div>
                    <ProfitChartUser data={sortedWalletData} height='h-[450px] -ms-10 md:ms-0' />
                </div>
            </div>

            {/* Bottom Stats */}
            <div className="text-lg flex flex-col md:flex-row md:px-0 gap-3 rounded-2xl text-white font-normal md:justify-around">
                {analyticsStat.map((card, index) => (
                    <TradeStatUser
                        key={index}
                        title={card.title}
                        value={card.value}
                        change={card.change}
                        buttonText={card.buttonText}
                        gradientBg={card.gradientBg}
                        isButtonVisible={card.isButtonVisible}
                        onClickAction={card.onClickAction}
                        addedClass={index === 0 ? 'md:w-[60%]' : 'md:w-[40%]'}
                    />
                ))}
            </div>
        </div>
    );
};

export default QuickAnalyticsUser;