import React from 'react';

const CourseCard = ({ title, description, imgSrc, isGradient = false }) => {
    return (
        <div
            className={`course-card ${isGradient ? 'gradient-border isGradient ' :
                'border-solid border-transparent rounded-lg animate-border-slide'}`}
        >
            <div>
                <span className="text-[#FFF] font-bold text-xl">{title}</span>
            </div>
            <div>
                <span className="text-[#FFF] text-opacity-50">{description}</span>
            </div>
            <div>
                <span className="get-started">Get Started</span>
            </div>
            <div className="top-icon">
                <img style={{ width: 60, height: 60 }} src={imgSrc} alt={title} />
            </div>
        </div>
    );
};

export default CourseCard;
