import React, { useEffect, useState } from 'react';
import blockchain_consulting from '../../assets/images/landing/blockchain_consulting.png';
import dApps from '../../assets/images/landing/dApps.png';
import tokendev from '../../assets/images/landing/tokendev.png';
import enterprise from '../../assets/images/landing/enterprise.png';
import contract from '../../assets/images/landing/contract.png';
import integration from '../../assets/images/landing/integration.png';
import FullstackBlockChain from '../../assets/images/landing/fullstack-blockchain.png';

const services = [
  { title: 'Blockchain Consulting', img: blockchain_consulting },
  { title: 'Decentralized Applications (dApps)', img: dApps },
  { title: 'Smart Contract Development', img: contract },
  { title: 'Enterprise Blockchain Solutions', img: enterprise },
  { title: 'Token Development', img: tokendev },
  { title: 'Custom Blockchain Development', img: FullstackBlockChain },
  { title: 'Blockchain Integration', img: integration },
];

export default function Services() {
  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index >= 0 && index <= 1) {
        setActiveIndex(index);
      } else if (index >= 2 && index <= 4) {
        setActiveIndex(4 - (index - 2)); // Reverse order for 2, 3, 4
      } else if (index >= 5) {
        setActiveIndex(index);
      }

      index = (index + 1) % services.length; // Loop back to the beginning
    }, 700); // Change element every 700ms
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="py-16">
      <div className="custom-border-left">
        <div className="top-div-container">
          {services.slice(0, 2).map((service, index) => (
            <div
              key={index}
              className={`flex flex-col gap-4 below-md:gap-1 items-center max-w-[200px] text-center ${
                activeIndex === index ? 'scale-110' : ''
              }`}
            >
              <div className="service-img-div">
                <img
                  className="h-[60px] w-[60px] below-md:h-[30px] below-md:w-[30px]"
                  src={service.img}
                  alt="service"
                />
              </div>
              <div>
                <span className="service-title">{service.title}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="bottom-div-container">
          {services.slice(2, 5).map((service, index) => (
            <div
              key={index + 2}
              className={`flex flex-col gap-4 below-md:gap-1 items-center max-w-[200px] text-center ${
                activeIndex === index + 2 ? 'scale-110' : ''
              }`}
            >
              <div className="service-img-div">
                <img
                  className="h-[60px] w-[60px] below-md:h-[20px] below-md:w-[20px]"
                  src={service.img}
                  alt="service"
                />
              </div>
              <div>
                <span className="service-title">{service.title}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="custom-border-right">
        <div className="bottom-div-right-container">
          {services.slice(5).map((service, index) => (
            <div
              key={index + 5}
              className={`flex flex-col gap-4 below-md:gap-1 items-center max-w-[200px] text-center ${
                activeIndex === index + 5 ? 'scale-110' : ''
              }`}
            >
              <div className="service-img-div">
                <img
                  className="h-[60px] w-[60px] below-md:h-[20px] below-md:w-[20px]"
                  src={service.img}
                  alt="service"
                />
              </div>
              <div>
                <span className="service-title">{service.title}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
