import { ChevronLeft, ChevronRight } from 'lucide-react';
import React from 'react'
import Pagging from '../Table/Pagging';

const WalletTable = ({ currentRecords, isMobile, graph, graphRed, loading, recordsPerPage = 5, currentPage, handlePageChange, recordsFiltered, extraClass = "" }) => {

    return (
        <>
            <div className={`mobileTable userDataTable md:min-h-[30rem] extraClass`}>
                {Array.isArray(currentRecords) && currentRecords?.length > 0 &&
                    (isMobile ?
                        <div className="w-full max-w-md mx-auto">
                            {currentRecords?.map((item, index) => (
                                <div key={index} className="flex space-x-4 items-center justify-between bg-[#202F4C] py-3">
                                    {/* Icon */}
                                    <div className="w-10 h-10 flex items-center justify-center mr-4">
                                        <span
                                            className={`w-full h-full rounded-full flex items-center justify-center`}
                                        >
                                            <img
                                                src={index % 2 === 0 ? graph : graphRed}
                                                alt="Transaction Icon"
                                                className="w-8 h-8"
                                            />
                                        </span>
                                    </div>

                                    {/* Date & Time */}
                                    <div className="flex-1 gap-1">
                                        <div className="text-gray-400 text-xs">Date & Time</div>
                                        <div className="text-white text-sm font-semibold">{item.updatedOn.slice(11)}</div>
                                        <div className="text-gray-400 text-xs">{item.updatedOn.slice(0, 10)}</div>
                                    </div>

                                    {/* Amount */}
                                    <div className="flex flex-col items-center">
                                        <div className="text-gray-400 text-xs">Amount</div>
                                        <div className="text-white text-sm font-semibold">{item.profitLoss}</div>
                                    </div>

                                    {/* Total Balance */}
                                    <div className="flex flex-col items-center">
                                        <div className="text-gray-400 text-xs">Total balance</div>
                                        <div className="text-white text-sm font-semibold">{item.balanceAmount}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className='userDataTableHeader'>P/L</th>
                                    <th className='userDataTableHeader'>Date & Time</th>
                                    <th className='userDataTableHeader'>Amount</th>
                                    <th className='userDataTableHeader'>Total balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <div className="skeleton">
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                    </div>
                                ) : (
                                    currentRecords?.map((item, index) => (
                                        <tr key={index} className='text-white items-center'>
                                            <td data-label="P/L">
                                                {/* <button className={`text-lg gradientbutton ${item?.profitLoss?.[0] === '+' ? 'profit-gradient' : 'loss-gradient'}`}>
                                            {item?.profitLoss?.[0] === '+' ? 'Profit' : 'Loss'}
                                        </button> */}
                                                <button className={`text-lg gradientbutton ${(item?.plType?.toLowerCase() === 'profit' || item?.plType?.toLowerCase() === 'credit') ? 'profit-gradient' : 'loss-gradient'}`}>
                                                    {item?.plType || 'N/A'}
                                                </button>
                                            </td>
                                            <td data-label="updatedOn" className="text-white text-lg">{item.updatedOn.slice(11)} <br></br> {item.updatedOn.slice(0, 10)}</td>
                                            <td data-label="Profit//Loss Value" className={`text-lg text-white`}>{item?.profitLoss}</td>
                                            <td data-label="Balance Amount" className="text-white text-lg">{item.balanceAmount}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>)}
            </div>
            <div className='flexRow bg-[#202F4C]' style={{ justifyContent: 'space-between', alignItems: 'center', padding: 24 }}>
                <div>
                    <span className='paginationResultText'>
                        Showing {(currentPage - 1) * recordsPerPage + 1} to {currentPage * recordsPerPage > recordsFiltered ? recordsFiltered : currentPage * recordsPerPage} of {recordsFiltered} transactions
                    </span>
                </div>
                <div className='flexRow' style={{ gap: 16 }}>
                    <Pagging
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        recordsFiltered={recordsFiltered}
                        recordsPerPage={recordsPerPage}
                    />
                </div>
            </div>
        </>

    );
}

export default WalletTable