import Cookies from 'js-cookie';
import TokenParser from '../Helpers/tokenParser';
import moment from 'moment';


export const getUserData = () => {
  let token = Cookies.get('token');
  let details = TokenParser(token);
  let data = details.payload;
  if (data) {
    return data
  }
  return null
}


export const getUserName = () => {
  let token = Cookies.get('token');
  let details = TokenParser(token);
  let name = details.payload?.username;
  if (name) {
    return name
  }
  return null
}

export const getFirstName = () => {
  let token = Cookies.get('token');
  let details = TokenParser(token);

  console.log(details)
  let name = details.payload?.username?.split(' ')?.[0];
  if (name) {
    return name + '!'
  }
  return null
}

export function calculateTotals(users) {
  return users.reduce(
    (totals, user) => {
      // Convert balance and profitLossAmount to numbers
      const balance = parseFloat(user?.balance?.replace(/[^0-9.-]+/g, '')) || 0;
      const profitLoss = parseFloat(user?.profitLossAmount?.replace(/[^0-9.-]+/g, '')) || 0;

      // Add to totals based on profit/loss type
      totals.totalProfitLoss += user.profitLossType === 'Profit' ? profitLoss : -profitLoss;
      totals.totalBalance += balance;
      totals.totalOpenTrades += user.openTrades || 0;

      return totals;
    },
    { totalProfitLoss: 0, totalBalance: 0, totalOpenTrades: 0 }
  );
}

// export const numericValue = (value) => parseFloat(value?.replace(/[^0-9.]/g, ''))
export const numericValue = (value) => {
  if (!value) return 0; // Handle null or undefined values
  const isNegative = value.trim().startsWith('-'); // Check for the minus sign at the start
  const numericPart = parseFloat(value.replace(/[^0-9.]/g, '')); // Extract numeric part
  return isNegative ? -numericPart : numericPart; // Apply negative sign if needed
};

export function formatToUSCurrency(value) {
  // Remove any existing dollar sign and convert the string to a number
  const numberValue = parseFloat(String(value).replace('$', '').replace(/,/g, ''));

  // Format the number to US currency without cents
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0, // Ensures no decimals are displayed
    maximumFractionDigits: 0  // Ensures no decimals are displayed
  }).format(numberValue);
}


export function groupByHourBalanceChart(data) {
  const result = {};

  data?.forEach(item => {
    // const date = new Date(item.date);
    // const hour = date.getHours();
    const timeLabel = item.updatedOn //`${String(hour).padStart(2, '0')}:00`;

    if (!result[timeLabel]) {
      result[timeLabel] = { balanceSum: 0, count: 0, originalItems: item };
    }

    result[timeLabel].balanceSum += parseFloat(item?.balanceAmount?.replace(/[^\d.-]/g, ''));
    result[timeLabel].count += 1;
  });

  return Object.entries(result).map(([time, { balanceSum, count, originalItems }]) => ({
    time,
    balance: parseFloat((balanceSum / count).toFixed(2)), // Average balance
    items: originalItems,
  }));
}

export function filterAndFormatData(data, filterParam) {
  // Get the current date
  const now = new Date();

  // Determine the start date for filtering
  let startDate;
  switch (filterParam) {
    case 'recent':
      startDate = new Date(now.getTime() - 60 * 60 * 1000); // last 1 hour
      break;
    case 'today':
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // start of today
      break;
    case '1 week':
      startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); // last 7 days
      break;
    case '1 month':
      startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()); // last 1 month
      break;
    default:
      throw new Error('Invalid filter parameter');
  }

  // Parse the date and filter the data
  const parseDate = dateStr => {
    // Replace the non-standard format into a standard ISO format for parsing
    const [date, time, period] = dateStr.split(/T| /);
    const isoDate = `${date} ${time} ${period}`;
    return new Date(isoDate);
  };

  // Filter the data based on the updatedOn date
  let filteredData = data.filter(item => parseDate(item.updatedOn) >= startDate);

  // If no data is found within the range, fallback to the first three entries
  if (filteredData.length === 0) {
    filteredData = data.slice(0, 3);
  }

  // Format the filtered data into the required format
  const result = filteredData.reduce((acc, curr) => {
    const date = parseDate(curr.updatedOn);
    const day = date.getDate(); // Extract the day of the month

    // Find or create the entry for the day
    let entry = acc.find(item => item.name === String(day));
    if (!entry) {
      entry = { name: date, loss: 0, profit: 0 };
      acc.push(entry);
    }

    // Add profit or loss values
    const profitLossValue = parseFloat(curr?.profitLoss?.replace(/[^\d.-]/g, ''));
    if (profitLossValue > 0) {
      entry.profit += profitLossValue;
    } else {
      entry.loss += Math.abs(profitLossValue);
    }

    return acc;
  }, []);

  return result.sort((a, b) => new Date(a.name) - new Date(b.name));
}


export function formatNumber(value) {
  if (typeof value === 'string') {
    // Remove $ sign and any commas, then parse as a float
    value = parseFloat(value.replace(/[^0-9.-]/g, ''));
  }

  if (isNaN(value)) {
    return '0'; // Handle invalid values gracefully
  }

  const absValue = Math.abs(value); // Handle negative values
  let formattedValue;

  if (absValue >= 1_000_000_000) {
    formattedValue = (value / 1_000_000_000).toFixed(2) + 'B'; // Billions
  } else if (absValue >= 1_000_000) {
    formattedValue = (value / 1_000_000).toFixed(2) + 'M'; // Millions
  } else if (absValue >= 1_000) {
    formattedValue = (value / 1_000).toFixed(2) + 'K'; // Thousands
  } else {
    formattedValue = value.toFixed(2); // Numbers less than 1,000 with 2 decimal places
  }

  // Ensure commas for thousands separators in the formatted value
  return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}


export function formatShortDateTime(date) {
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error("Invalid Date object");
  }

  // Format the date-time to a short format
  return new Intl.DateTimeFormat('en-US', {
    dateStyle: 'short',
    timeStyle: 'short',
  }).format(date);
}


export const updateCardsData = (userData, cardsData, setCardData, analyticsStat, setAnalyticsStat) => {
  const updatedCardsData = cardsData.map((card) => {
    switch (card.id) {
      case 'balance':
        return {
          ...card,
          value: userData?.balance?.replace('+', ''), // Remove "+" from balance
          change: (
            parseFloat(userData?.balance?.replace(/[$+,]/g, '')) -
            parseFloat(userData?.previousBalance?.replace(/[$+,]/g, ''))
          ).toFixed(2), // Calculate the change
        };
      case 'totalProfitLoss':
        return {
          ...card,
          value: userData.profitLossAmount.replace('+', ''), // Remove "+" from profit/loss amount
          change: userData.profitLossType === 'Profit' ? '+3.9%' : '-3.9%',
        };
      case 'openTrades':
        return {
          ...card,
          value: userData.openTrades.toString(), // Convert number to string
          days: null, // Assuming 'days' remains constant
        };
      default:
        return card; // Return the card as-is for other IDs
    }
  });

  const updateAnalytics = analyticsStat.map((card) => {
    switch (card.id) {
      case 'funds':
        return {
          ...card,
          value: '$' + (userData?.initialInvestmentAmount?.replace('+', ''))
        };

      case 'balance':
        return {
          ...card,
          value: '$' + (userData?.assetsBalance?.replace('+', '')),
          change: userData?.investedFundsPct + '%'
        };
      default:
        return card; // Return the card as-is for other IDs
    }
  });

  setCardData(updatedCardsData);
  setAnalyticsStat(updateAnalytics)
};

export const transformToAssets = (dataArray) => {
  return dataArray.map(item => ({
    ...item,
    name: item.coinName.charAt(0).toUpperCase() + item.coinName.slice(1),
    amount: item.amountInvested,
    avgPrice: item.averagePrice,
    currentValue: item.currentValue
  }));
};

export const makeUtcFiveHrsAgo = (updatedAt) => {
  if (!updatedAt) { return null }
  const date = new Date(updatedAt); // Parse the UTC time
  const utc5Offset = -5 * 60; // UTC-5 in minutes
  const utc5Time = new Date(date.getTime() + utc5Offset * 60 * 1000);

  // Format the adjusted time to display
  const options = { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric", hour12: true };
  const formattedDate = utc5Time.toLocaleString("en-US", options);

  return formattedDate
}

// export function mergeDataByDay(data) {
//   if (data?.length === 0) { return [] }

//   // Helper function to parse balanceAmount and profitLoss to numbers
//   const parseValue = (value) => parseFloat(value?.replace(/[+$,]/g, ""));

//   // Reduce data to merge entries with the same day
//   const mergedData = data?.reduce((acc, item) => {
//     const dateKey = moment(item?.updatedOn, "MM-DD-YYYYTHH:mm:ss A").format("MM-DD-YYYY");

//     if (!acc[dateKey]) {
//       acc[dateKey] = {
//         balanceAmount: parseValue(item?.balanceAmount),
//         profitLoss: parseValue(item?.profitLoss),
//         updatedOn: item.updatedOn,
//         profit: 0, // Initialize profit as 0
//       };
//     } else {
//       // Update balanceAmount and profitLoss for the same date
//       acc[dateKey].balanceAmount += parseValue(item.balanceAmount);
//       acc[dateKey].profitLoss += parseValue(item.profitLoss);
//     }

//     return acc;
//   }, {});

//   // Convert the object back to an array
//   const mergedArray = Object.values(mergedData);

//   // Calculate profit (difference between current and previous day balance)
//   for (let i = 1; i < mergedArray.length; i++) {
//     const current = mergedArray[i];
//     const previous = mergedArray[i - 1];

//     // Calculate the profit difference
//     current.profit =
//       previous.balanceAmount !== 0 ?
//         ((current.balanceAmount - previous.balanceAmount) / previous.balanceAmount * 100) : 0
//   }

//   return mergedArray;
// }

export function mergeDataByDay(data) {
  if (!data || data.length === 0) return [];

  // Helper function to parse balanceAmount and profitLoss to numbers
  const parseValue = (value) => parseFloat(value?.replace(/[+$,]/g, ""));

  // Reduce data to merge entries with the same day
  const mergedData = data.reduce((acc, item) => {
    const dateKey = moment(item?.updatedOn, "MM-DD-YYYYTHH:mm:ss A").format("MM-DD-YYYY");

    if (!acc[dateKey]) {
      acc[dateKey] = {
        balanceAmount: parseValue(item?.balanceAmount),
        profitLoss: parseValue(item?.profitLoss),
        updatedOn: item?.updatedOn,
        profit: 0, // Initialize profit as 0
      };
    } else {
      // Update profitLoss for the same date
      acc[dateKey].profitLoss += parseValue(item.profitLoss);

      // Keep the most recent balanceAmount based on updatedOn
      const currentUpdatedOn = moment(item?.updatedOn, "MM-DD-YYYYTHH:mm:ss A");
      const existingUpdatedOn = moment(acc[dateKey].updatedOn, "MM-DD-YYYYTHH:mm:ss A");

      if (currentUpdatedOn.isAfter(existingUpdatedOn)) {
        acc[dateKey].balanceAmount = parseValue(item.balanceAmount);
        acc[dateKey].updatedOn = item.updatedOn;
      }
    }

    return acc;
  }, {});

  // Convert the object back to an array
  const mergedArray = Object.values(mergedData);

  // Calculate profit (difference between current and previous day balance)
  for (let i = 1; i < mergedArray.length; i++) {
    const current = mergedArray[i];
    const previous = mergedArray[i - 1];

    // Calculate the profit difference
    current.profit =
      previous.balanceAmount !== 0
        ? ((current.balanceAmount - previous.balanceAmount) / previous.balanceAmount) * 100
        : 0;
  }

  return mergedArray;
}


export const getUniqueObjects = (array, key) => {
  const seen = new Set();
  return array.filter((item) => {
    const value = item[key];
    if (seen.has(value)) {
      return false;
    }
    seen.add(value);
    return true;
  });
};

export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};