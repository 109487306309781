import React from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
// import '../Styles/TopBar.css';
import TokenParser from '../Helpers/tokenParser';
import logo from '../assets/images/logo.svg';
import { Bell, HelpCircle, Mail } from 'lucide-react';
import '../assets/css/common.css'
import TopInfoSection from './Profile/TopInfoSection';
import LandingSideBarMobile from './LandingSideBarMobile';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import Footer from './Footer';

const LandingPageTopbar = ({ sections }) => {
    console.log('checking', sections)
    const navigate = useNavigate();
    const location = useLocation();
    const [active, setActive] = useState('home');
    const [role, setRole] = useState('')
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    let token = Cookies.get('token');

    const scrollToSection = (sectionName) => {
        if (sections && sections.current && sections.current[sectionName]) {
            sections.current[sectionName].scrollIntoView({ behavior: "smooth" });
        } else {
            console.error(`Section ${sectionName} not found.`);
        }
    };

    useEffect(() => {
        let details = TokenParser(token);
        if (details) {
            let firstLetter = details.payload?.username;
            let email = details.payload?.email;
            if (firstLetter) {
                setFirstName(firstLetter.substring(0, 1))
                setName(firstLetter)
                setRole(details.payload.role)
            }
            if (email) {
                setEmail(email)
            }
        }
    }, [])

    useEffect(() => {
        // if (!token && location.pathname !== '/landing') {
        //     Logout()
        // }
    }, [])

    const Logout = () => {
        Cookies.remove('token');
        navigate('/login')
    }

    const handleClick = (toVar, toRef) => {
        setActive(toVar);
        scrollToSection(toRef);
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: '100vh'
        }}>
            {/* LandingPageTopbar Layout */}
            <nav className="bg-[#07142F] w-full px-4 md:px-8 flex justify-between items-center py-5">
                {/* Logo */}
                <Link className="flex items-center" to={role === 'user' ? '/user/home' : "/admin/home"}>
                    <img src={logo} alt="Havok Labs" className="h-10" />
                </Link>

                <LandingSideBarMobile handleClick={handleClick} />


                {/* Navigation Links (Hidden on mobile) */}
                <div className="hidden md:flex md:w-5xl space-x-10 gap-6 md:justify-between text-[#9CA1AC]">
                    <span
                        className={(active == 'home') ? 'text-[#3CC8C8] font-bold cursor-pointer' : 'text-white font-semibold cursor-pointer'}
                        onClick={() => { handleClick('home', "section1") }}
                    >
                        HOME
                    </span>
                    <span
                        className={(active == 'aboutus') ? 'text-[#3CC8C8] font-bold cursor-pointer' : 'text-white font-semibold cursor-pointer'}
                        onClick={() => { handleClick('aboutus', "section2") }}
                    >
                        ABOUT US
                    </span>
                    <span
                        className={(active == 'services') ? 'text-[#3CC8C8] font-bold cursor-pointer' : 'text-white font-semibold cursor-pointer'}
                        onClick={() => { setActive('services'); scrollToSection("section3") }}
                    >
                        SERVICES
                    </span>
                    <span
                        className={(active == 'solutions') ? 'text-[#3CC8C8] font-bold cursor-pointer' : 'text-white font-semibold cursor-pointer'}
                        onClick={() => { setActive('solutions'); scrollToSection("section4") }}
                    >
                        SOLUTIONS
                    </span>
                    <span
                        className={(active == 'blog') ? 'text-[#3CC8C8] font-bold cursor-pointer' : 'text-white font-semibold cursor-pointer'}
                        onClick={() => { setActive('blog'); scrollToSection("section5") }}
                    >
                        BLOG
                    </span>
                    {/* <span
                        className={(active == 'faq') ? 'text-[#3CC8C8] font-bold cursor-pointer' : 'text-white font-semibold cursor-pointer'}
                        onClick={() => {setActive('faq'); scrollToSection("section6")}}
                    >
                        FAQ
                    </span> */}
                    <span
                        className={(active == 'contact') ? 'text-[#3CC8C8] font-bold cursor-pointer' : 'text-white font-semibold cursor-pointer'}
                        onClick={() => { handleClick('contact', "section7") }}
                    >
                        CONTACT
                    </span>
                </div>
                <div className='below-md:hidden flex flex-row gap-5 items-center'>
                    {/* <span className='text-white font-semibold cursor-pointer'
                    onClick={() => {navigate('/login')}}
                    >
                        Log in
                    </span> */}
                    <Button
                        className='getstarted-btn'
                        onClick={() => { navigate('/login') }}
                    >
                        Log in
                    </Button>
                </div>
            </nav>
            <div style={{ background: 'linear-gradient(90deg, #07142F 0%, #7E6CFF 50%, #07142F 100%)', width: '100%', height: 1.5 }}></div>
            <div className='overflow-auto scrollbar-hidden' style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                // overflow: 'hidden',
                height: '100%'
            }}>
                <div className="rounded-tl-[30px] rounded-tr-[30px] scrollbar-hidden min-h-screen max-h-auto overflow-y-auto bg-[#07142F]">
                    {/* <div className=' md:px-10'> */}
                    <div className='overflow-y-scroll scrollbar-hidden'
                        style={{
                            msOverflowStyle: 'none',
                            scrollbarWidth: 'none',
                        }}>
                        {/* This renders the child routes */}
                        <Outlet />
                        <ToastContainer stacked />
                    </div>
                    {/* </div> */}
                    <Footer handleClick={handleClick} />
                </div>
            </div>
        </div>
    );
};

export default LandingPageTopbar;
