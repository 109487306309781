import { ChevronLeft, ChevronRight } from 'lucide-react';
import React from 'react'

const Pagging = ({ currentPage, handlePageChange, recordsFiltered, recordsPerPage, slug }) => {
    const buttons = [];

    // Add Previous button if currentPage is greater than 1
    if (currentPage > 1) {
        buttons.push(
            <button
                className="font-medium text-[#32CCCB]"
                key="prev"
                onClick={() => handlePageChange(currentPage - 1)}
            >
                <ChevronLeft color="white" />
            </button>
        );
    }

    const totalPages = Math.ceil(recordsFiltered / recordsPerPage);
    const maxVisiblePages = 5; // Limit visible pages to a fixed number
    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

    // Adjust startPage if endPage reaches the last page
    if (endPage === totalPages) {
        startPage = Math.max(totalPages - maxVisiblePages + 1, 1);
    }

    // Render pagination buttons for visible range of pages
    for (let i = startPage; i <= endPage; i++) {
        buttons.push(
            <button
                className={`font-medium ${currentPage === i ? 'text-[#32CCCB]' : 'text-white'}`}
                key={i}
                onClick={() => handlePageChange(i)}
            >
                {i}
            </button>
        );
    }

    // Add Next button if currentPage is less than totalPages
    if (currentPage < totalPages) {
        buttons.push(
            <button
                className="font-medium text-[#32CCCB]"
                key="next"
                onClick={() => handlePageChange(currentPage + 1)}
            >
                <ChevronRight color="white" />
            </button>
        );
    }

    return buttons;
};

export default Pagging