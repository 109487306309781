import React, { useEffect, useState } from 'react'
import EmailInbox from './EmailInbox';
import Cookies from 'js-cookie';
import moment from "moment";
import axios from "axios";
import { getUniqueObjects } from '../../services/common.utils';
import TokenParser from '../../Helpers/tokenParser';
import { toast } from 'react-toastify';

function MessagesIndex() {
    // let dummyMessage = [
    //     {
    //         id: 1,
    //         subject: 'Meeting Agenda',
    //         sender: 'John Doe',
    //         date: new Date('2024-12-18T10:00:00'),
    //         body: 'Please find the agenda for the upcoming meeting...',
    //         type: 'received',
    //     },
    //     {
    //         id: 2,
    //         subject: 'Project Update',
    //         sender: 'Jane Smith',
    //         date: new Date('2024-12-18T12:30:00'),
    //         body: 'I wanted to provide an update on the progress of the project...',
    //         type: 'received',
    //     },
    //     {
    //         id: 3,
    //         subject: 'Fund Received',
    //         sender: 'Admin',
    //         date: new Date('2024-12-18T09:00:00'),
    //         body: 'We are pleased to confirm that the fund of $23,718 has been received.',
    //         type: 'received',
    //     },
    //     {
    //         id: 4,
    //         subject: 'Invoice Sent',
    //         sender: 'You',
    //         date: new Date('2024-12-18T14:00:00'),
    //         body: 'Please find attached the invoice for the last quarter.',
    //         type: 'sent',
    //     },
    //     {
    //         id: 5,
    //         subject: 'Reminder: Submission Due',
    //         sender: 'HR Team',
    //         date: new Date('2024-12-17T15:00:00'),
    //         body: 'This is a reminder to submit your timesheet before the deadline.',
    //         type: 'received',
    //     },
    //     {
    //         id: 6,
    //         subject: 'Proposal Review',
    //         sender: 'Client A',
    //         date: new Date('2024-12-16T08:30:00'),
    //         body: 'We have reviewed the proposal and would like to discuss further details.',
    //         type: 'received',
    //     },
    //     {
    //         id: 7,
    //         subject: 'Approval Confirmation',
    //         sender: 'You',
    //         date: new Date('2024-12-16T16:00:00'),
    //         body: 'Please confirm that the project plan has been approved.',
    //         type: 'sent',
    //     },
    //     {
    //         id: 8,
    //         subject: 'Meeting Follow-up',
    //         sender: 'Project Lead',
    //         date: new Date('2024-12-15T11:00:00'),
    //         body: 'Following up on the discussion points from our last meeting...',
    //         type: 'received',
    //     },
    //     {
    //         id: 9,
    //         subject: 'Action Required: Budget Report',
    //         sender: 'Finance Team',
    //         date: new Date('2024-12-14T09:45:00'),
    //         body: 'Please review and finalize the budget report for this quarter.',
    //         type: 'received',
    //     },
    //     {
    //         id: 10,
    //         subject: 'Thank You Note',
    //         sender: 'You',
    //         date: new Date('2024-12-14T17:00:00'),
    //         body: 'Thank you for your assistance with the recent deliverables!',
    //         type: 'sent',
    //     },
    // ]
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortby, setSortby] = useState('latest');
    const [currentPageUser, setCurrentPageUser] = useState(1);
    const [totalPagesUser, setTotalPagesUser] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const recordsPerPage = 300;

    const [activeMessage, setActiveMessage] = useState(null);
    let token = Cookies.get('token');
    let details = TokenParser(token);


    const convertDataIntoState = async (data = [], type) => {
        // Ensure `data` is an array
        if (!Array.isArray(data) || data.length === 0) {
            return [];
        }

        const transformedData = data?.map((item, index) => ({
            ...item,
            id: item.id,
            subject: item.subject || "No subject",
            sender: item?.senderName, //|| details.payload.role.toLowerCase() !== 'admin' ? "Admin" : item.username ? item.username : "Unknown",
            date: new Date(item.updatedAt),
            body: item.message || "Closed",
            type: type,
        }));

        return transformedData //getUniqueObjects(transformedData, 'id')
    }


    // let commonParams = `sortby=${sortby}${searchValue !== '' ? '&searchValue=' + searchValue : ''}&page=${currentPageUser}&recordsPerPage=${recordsPerPage}`;

    const fetchSentInquiries = async (headers, baseApi) => {
        try {
            const sentApi = `${baseApi}sent`;
            const sentResponse = await axios.get(sentApi, { headers });
            const sentData = sentResponse?.data?.contactEmailDetails || [];
            const transformedSent = await convertDataIntoState(sentData, 'sent');
            return transformedSent;
        } catch (error) {
            console.error('Error fetching sent inquiries:', error);
            return [];
        }
    };

    const fetchInboxInquiries = async (headers, baseApi) => {
        try {
            const inboxApi = `${baseApi}inbox`;
            const inboxResponse = await axios.get(inboxApi, { headers });
            const inboxData = inboxResponse?.data?.contactEmailDetails || [];
            const transformedInbox = await convertDataIntoState(inboxData, 'received');
            return transformedInbox;
        } catch (error) {
            console.error('Error fetching inbox inquiries:', error);
            return [];
        }
    };

    const fetchInquiries = async () => {
        try {
            setLoading(true);

            let baseApi = process.env.REACT_APP_BACKEND;
            let headers = {
                Authorization: `Bearer ${token}`,
            };

            // Fetch sent and inbox data sequentially
            const transformedSent = await fetchSentInquiries(headers, baseApi);
            const transformedInbox = await fetchInboxInquiries(headers, baseApi);

            // Update state with processed data
            setMessages({
                sent: transformedSent,
                inbox: transformedInbox,
            });

        } catch (error) {
            console.error('Error in fetchInquiries:', error);
            // toast.error('Failed to fetch inquiries. Please try again later.');
        } finally {
            setLoading(false);
        }
    };


    // useEffect(() => {
    //     fetchInquiries();
    // }, [currentPageUser, searchValue, sortby]);

    useEffect(() => {
        fetchInquiries();
    }, []);

    // send new message
    const onSendMessage = () => {

    }
    // console.log(messages)
    return (
        <EmailInbox
            messages={messages}
            activeMessage={activeMessage}
            setActiveMessage={setActiveMessage}
            searchValue={searchValue} setSearchValue={setSearchValue}
            fetchInquiries={fetchInquiries} />
    )
}

export default MessagesIndex