import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
// import TokenParser from '../../Helpers/tokenParser';
import InquiryResponse from '../Users/InquiryResponse';
import axios from 'axios';

const MessageDetails = ({ message, isOpenResponse, setIsOpenResponse, fetchInquiries }) => {
    let token = Cookies.get('token');
    // let details = TokenParser(token);
    const [threads, setThreads] = useState([]);

    const [messageDetails, setDetails] = useState({})
    const [lastMessage, setLastMessage] = useState({})
    const [loading, setLoading] = useState(false)
    let baseApi = process.env.REACT_APP_BACKEND;
    let headers = {
        Authorization: `Bearer ${token}`,
    };

    const { sender, userEmail, date, subject, body, recipientName } = messageDetails;

    const fetchDetails = async () => {
        setLoading(true)
        try {
            const sentApi = `${baseApi}thread?messageId=${message.id}`;
            const sentResponse = await axios.get(sentApi, { headers });
            // const sentData = sentResponse?.data?.threads?.[0] || [];
            // setDetails(transformMessageData(sentData))
            const sentData = sentResponse?.data?.threads?.map(e => { return { ...e, canReply: sentResponse?.data?.canReply, threadClosed: sentResponse?.data?.threadClosed } })?.reverse() || [];
            setThreads(sentData.map(transformMessageData));
        } catch (error) {
            console.error('Error fetching sent inquiries:', error);
            return [];
        } finally {
            setLoading(false)
        }
    };

    const transformMessageData = (data) => {
        return {
            ...data,
            sender: data.senderName,
            userEmail: data.senderEmail,
            date: data.createdAt,
            subject: data.subject,
            body: data.message,
            // type: type, // Either 'sent' or 'inbox', depending on context
        };
    };

    const handleRespondMsg = () => {
        setIsOpenResponse(true)
    }

    useEffect(() => {
        if (message?.id) {
            fetchDetails()
        }
    }, [message?.id])

    useEffect(() => {
        if (threads?.length > 0) {
            setLastMessage(threads[threads?.length - 1])
        }
    }, [threads])
    return (
        loading ? (
            <div>
                {/* Loader Header */}
                <div className="mt-3 w-full h-8 bg-[#07142FAA] rounded-md animate-pulse"></div>

                {/* Loader Body */}
                <div className="space-y-3">
                    <div className="w-full h-24 bg-[#07142F] rounded-md animate-pulse"></div>
                    <div className="w-full h-14 bg-[#07142F] rounded-md animate-pulse"></div>
                    <div className="w-full h-[50vh] bg-[#07142F] rounded-md animate-pulse"></div>
                </div>

                {/* Loader Footer */}
                <div className="w-full h-14 bg-[#07142F] rounded-md animate-pulse"></div>
            </div>
        ) :
            isOpenResponse ?
                <InquiryResponse data={threads[threads?.length - 1]} setIsOpenResponse={setIsOpenResponse} getInquiries={fetchInquiries} fetchDetails={fetchDetails} /> :

                <div className='h-[90vh]'>
                    <div className="text-white relative h-[80vh] overflow-y-auto scrollbar-thin scrollbar-thumb-[#374151] scrollbar-track-transparent">
                        {/* Thread List Header */}
                        {/* <div className="text-lg font-bold mb-4">Message Threads</div> */}

                        {threads.length > 0 ? (
                            threads.map((thread, index) => (
                                thread.body &&
                                <div key={index} className='border-slate-700 border-b pb-3 pt-5'>
                                    {/* Header Section */}
                                    <div className="flex flex-col md:flex-row justify-between md:items-center gap-5 pb-4 mb-4">
                                        <div className="flex items-center">
                                            <div className="w-10 h-10 bg-[#26DDB9] rounded-xl flex items-center justify-center text-gray-900 font-bold text-lg">
                                                {thread.sender?.charAt(0)?.toUpperCase()}
                                            </div>
                                            <div className="ml-3">
                                                <h2 className="font-semibold text-lg uppercase">{thread.sender}</h2>
                                                <p className="text-gray-400 text-sm">{'To : ' + thread.recipientName}</p>
                                            </div>
                                        </div>

                                        <p className="text-white text-sm">
                                            {thread.date ? moment(thread.date).format('Do MMM, YYYY, hh:mma') : ''}
                                        </p>
                                    </div>

                                    {/* Subject */}
                                    <h1 className="text-2xl font-semibold mb-4">{thread.subject}</h1>

                                    {/* Body Content */}
                                    <div className="text-white font-medium leading-relaxed">
                                        {thread.body?.split('\n').map((line, index) => (
                                            <p key={index} className="mb-4">
                                                {line}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            ))

                        ) : (
                            <p className="text-gray-400">No threads available.</p>
                        )}

                        {lastMessage?.canReply === true ?
                            <button
                                type='button'
                                disabled={lastMessage?.threadClosed === true}
                                onClick={() => handleRespondMsg()}
                                className={`${lastMessage?.threadClosed === true ? 'bg-[#2B3C5E] text-opacity-50' : 'bg-[#24DEB9]'} absolute left-4 mt-5 text-sm text-white px-5 py-2 rounded-full border-0`}
                            >
                                {lastMessage?.threadClosed === true ? 'Resolved' : 'Respond'}
                            </button> :
                            lastMessage?.threadClosed === false ?
                                <p className='text-red-400 font-medium text-sm mt-5'>You cannot reply. Please wait for a response from the recipient.</p> :
                                <p className='mt-5'>
                                    <span className={`bg-[#2B3C5E] text-opacity-50 text-white rounded-full px-8 py-1`}>
                                        Closed
                                    </span>
                                </p>
                        }
                    </div>
                </div>
    );
};

export default MessageDetails;
