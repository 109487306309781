import moment from 'moment-timezone'
import React from 'react'
import placeholderImg from '../../assets/images/sampleNewsDetail.svg'

function NewsCard({ article, navigate }) {
    const handleNavigate = () => {
        if (article?.url) {
            window.open(article.url, '_blank', 'noopener,noreferrer');
        }
    };
    return (
        <div
            key={article?.id}
            onClick={handleNavigate}
            className="cursor-pointer bg-[#202F4C] rounded-2xl"
        >
            <div className="flex flex-col md:flex-row items-center p-4 gap-4 md:gap-10 bg-dark-blue rounded-lg shadow-lg">
                {/* Image Section */}
                <div className="w-full h-52 md:h-54 md:w-auto flex-shrink-0 mb-4 md:mb-0">
                    <img
                        src={article?.image}
                        alt={article?.title}
                        className="md:w-[350px] w-full h-full object-cover rounded-md"
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = placeholderImg;
                        }}
                    />
                </div>

                {/* Text Section */}
                <div className="md:flex-1 min-w-0 flex flex-col gap-2">
                    <div className="flex items-center gap-2 text-gray-400 text-sm">
                        {article?.authors?.[0]?.name && <span>By <span className='text-[#33CCCC] capitalize'>{article?.authors?.[0]?.name || 'Author'}</span></span>}
                        {article?.authors?.[0]?.name && <span> | </span>}
                        <span>{article?.dateTime ? moment(article?.dateTime).startOf('hour').fromNow() : ''}</span>
                    </div>

                    <h2 className="text-lg md:text-xl font-semibold text-white">
                        {article?.title}
                    </h2>

                    <p className="text-gray-400 text-sm md:text-md line-clamp-2">
                        {article?.body}
                    </p>

                    <a
                        href={article?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#33CCCC] text-sm mt-2"
                    >
                        Read more...
                    </a>
                </div>
            </div>
        </div>
    )
}

export default NewsCard