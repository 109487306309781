import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import NewsSearchSort from './NewsSearchSort';
import { getFirstName } from '../../services/common.utils';
import NewsCard from '../News/NewsCard';
import Pagging from '../../Components/Table/Pagging';

const NewsFeed = ({ title, articles, loading = false, currentPage, recordsPerPage, recordsFiltered, handlePageChange }) => {
  const navigate = useNavigate();
  return (
    <div className='md:bg-[#202F4C] md:p-10 md:rounded-2xl'>
      <h1 className="text-2xl font-bold text-[#37B3B7] mb-6 text-center md:text-start">{title}</h1>
      <div className="space-y-4 md:h-[90vh] overflow-y-auto scrollbar-none">
        {loading ? (
          <div className="skeleton">
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
          </div>
        ) :
          articles?.map((article) => (
            <NewsCard article={article} navigate={navigate} />
          ))}
      </div>
      {recordsFiltered > recordsPerPage && <div className='flexRow bg-[#202F4C]' style={{ justifyContent: 'space-between', alignItems: 'center', padding: 24 }}>
        <div>
          <span className='paginationResultText'>
            Showing {(currentPage - 1) * recordsPerPage + 1} to {currentPage * recordsPerPage > recordsFiltered ? recordsFiltered : currentPage * recordsPerPage} of {recordsFiltered} news
          </span>
        </div>
        <div className='flexRow' style={{ gap: 16 }}>
          <Pagging
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            recordsFiltered={recordsFiltered}
            recordsPerPage={recordsPerPage}
          />
        </div>
      </div>}

    </div>
  );
};

export default NewsFeed;