import * as React from "react";
import { useRef } from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

import App from "./App";
import Login from '../src/Screens/Login'
import ResetPassword from '../src/Screens/ResetPassword'
import Dashboard from "./Screens/DashBoard";
import Profile from "./Screens/Profile";
import Trade from "./Screens/Trade";
import AddUser from "./Screens/Users/AddUser";
import UserDashBoardAdminView from "./Screens/UserDashBoardAdminView";
// import Topbar from "./Components/TopBar";
import DashboardHome from "./Screens/DashboardHome";
import MarketDetails from "./Screens/Market/MarketDetails";
import LoginPage from "./Components/Auth/Login";
import AdminDashboard from "./Screens/AdminDashboard";
import UserDashboard from "./Screens/Dashboard/UserDashboard";
import Information from "./Components/Profile/Information";
import News from "./Screens/News";
import NewsDetails from "./Screens/NewsDetails";
import ResetPasswordUser from "./Screens/Users/ResetPassword";
import LandingPage from "./Screens/LandingPage";
import LandingPageWrapper from "./Screens/LandingPageWrapper";
import InquiriesHome from "./Screens/Inquiries/InquiriesHome";
import AssetDetails from "./Screens/Assets/AssetDetails";
import MessagesIndex from "./Screens/Messages/MessagesIndex";

const AppRouter = () => {
  const sectionsRef = useRef(null);

const router = createBrowserRouter([
  {
    path: "*",
    element: <LoginPage />
  },
  {
    path: "/login",
    element: <LoginPage />
  },
  {
    path: "/login-old",
    element: <Login />
  },
  {
    path: "/resetPassword",
    element: <ResetPassword />
  },
  {
    path: '/',
    element: <LandingPageWrapper sections={sectionsRef}/>,
    children: [
      {
        path: '',
        element: <LandingPage ref={sectionsRef}/>
      },
    ]
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: 'home',
        element: <AdminDashboard />
      },
      {
        path: 'admin/home',
        element: <DashboardHome />
      },
      {
        path: 'admin/home-old',
        element: <AdminDashboard />
      },
      {
        path: 'admin/users/:userId',
        element: <UserDashBoardAdminView />
      },
      {
        path: 'profile',
        element: <Information />
      },
      {
        path: 'market',
        element: <Trade />
      },
      {
        path: 'market/:id',  
        element: <MarketDetails />
      },
      {
        path: 'news',
        element: <News />
      },
      {
        path: 'news/:newsid',
        element: <NewsDetails />
      },
      {
        path: 'adduser',
        element: <AddUser />
      },
      {
        path: 'inquiries',
        element: <InquiriesHome />
      },
      {
        path: 'messages',
        element: <MessagesIndex />
      },
    
      // user routes
      {
        path: 'user/home',
        element: <UserDashboard />
      },
      {
        path: 'user/reset-password',
        element: <ResetPasswordUser />
      },
      {
        path: 'user/assets',
        element: <AssetDetails />
      },
    ]
  },
  

  ]);

  return <RouterProvider router={router} />;
}

createRoot(document.getElementById("root")).render(<AppRouter />);