import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip } from 'chart.js';
import btcIcon from '../../assets/images/btc.svg'
import NewsFeed from './NewsFeed';
// news feed images
import img1 from '../../assets/images/market/1.png'
import img2 from '../../assets/images/market/2.png'
import img3 from '../../assets/images/market/3.png'
import { getFirstName, getUserName } from '../../services/common.utils';
import LeftBarMarketData from './LeftBarMarketData';
import ChartPanel from './ChartPanel';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageLoader from '../../Components/Common/PageLoader';
import moment from 'moment-timezone';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip);

// export const articles = [
//     {
//         id: 1,
//         title: "Bitcoin Maintains Stability While Altcoins Rally",
//         description: "Bitcoin is currently trading at approximately $67,770, displaying stability as altcoins experience a notable rebound. The recent activity in the market has generated interest in cryptocurrencies such as XRP, SOL, and AVAX. Continue Reading:Bitcoin Maintains Stability While Altcoi... ",
//         timeAgo: "13m ago",
//         image: img1,
//         source: "CRYPTO NEWS"
//     },
//     {
//         id: 2,
//         title: "Bitcoin Maintains Stability While Altcoins Rally",
//         description: "Bitcoin is currently trading at approximately $67,770, displaying stability as altcoins experience a notable rebound. The recent activity in the market has generated interest in cryptocurrencies such as XRP, SOL, and AVAX. Continue Reading:Bitcoin Maintains Stability While Altcoi... ",
//         timeAgo: "15m ago",
//         image: img2,
//         source: "CRYPTO NEWS"
//     },
//     {
//         id: 3,
//         title: "Bitcoin Maintains Stability While Altcoins Rally",
//         description: "Bitcoin is currently trading at approximately $67,770, displaying stability as altcoins experience a notable rebound. The recent activity in the market has generated interest in cryptocurrencies such as XRP, SOL, and AVAX. Continue Reading:Bitcoin Maintains Stability While Altcoi... ",
//         timeAgo: "18m ago",
//         image: img3,
//         source: "CRYPTO NEWS"
//     }
// ]

const MarketDetails = () => {
    const navigate = useNavigate()
    const [name, setName] = useState(getUserName());
    const [loading, setLoading] = useState(true);
    const [showContent, setShowContent] = useState(false);
    let token = Cookies.get('token');
    const [data, setData] = useState({})
    // const threshold = 29800;
    // const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const [links, setLinks] = useState([
        { value: 'price', title: 'Price' },
        { value: 'market', title: 'Market Cap' },
    ])
    const { id } = useParams()
    const [articlesData, setArticlesData] = useState([])
    const [articleLoading, setArticleLoading] = useState(false)
    const [totalRecordsArticle, setTotalRecordsArticle] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const recordsPerPage = 10;
    const [historicalData, setHistoricalData] = useState([])
    const [selectedType, setSelectedType] = useState('price');
    const [interval, setInterval] = useState('1d')
    const [chartLoading, setChartLoading] = useState(false)

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    color: '#8F9BB3',
                    callback: function (value, index) {
                        return moment(historicalData[index]?.timestamp).format('Do MMM, YYYY');
                    },
                },
                grid: { display: false },
            },
            y: {
                ticks: {
                    color: '#8F9BB3',
                    callback: function (value) {
                        if (value >= 1e12) return (value / 1e12).toFixed(1) + 'T'; // Trillions
                        if (value >= 1e9) return (value / 1e9).toFixed(1) + 'B';  // Billions
                        if (value >= 1e6) return (value / 1e6).toFixed(1) + 'M'; // Millions
                        if (value >= 1e3) return (value / 1e3).toFixed(1) + 'K'; // Thousands
                        return value.toFixed(3); // Default
                    },
                },
                grid: { color: '#2E3A59' },
            },
        },
        plugins: {
            legend: { display: false },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function (tooltipItem) {
                        return `$${tooltipItem.raw.toLocaleString()}`;
                    }
                }
            },
        },
        chart: {
            type: 'line',
        },

    };

    function formatNumber(num, commaSeparated = false) {
        if (num == null) return 'N/A';
        return commaSeparated
            ? Number(num).toLocaleString(undefined, { maximumFractionDigits: 2 })
            : Number(num).toFixed(2);
    }

    function formatCurrency(num) {
        if (num == null) return 'N/A';
        return `$${Number(num).toLocaleString(undefined, { maximumFractionDigits: 2 })}`;
    }

    function convertCryptoData(input) {
        return {
            ...input,
            price: formatNumber(input.price, true),
            volume: formatNumber(input.volume24h, true),
            marketCap: formatCurrency(input.marketCap),
            fdv: input.totalSupply
                ? formatCurrency(input.totalSupply * input.price)
                : 'N/A',
            totalSupply: input.totalSupply
                ? `${formatNumber(input.totalSupply)} ${input.symbol}`
                : 'N/A',
            allTimeHigh: input?.performanceStats?.allTimeHigh,
            allTimeLow: input?.performanceStats?.allTimeLow,
            // rating: '--',
        };
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            let headers = {
                Authorization: `Bearer ${token}`
            }
            const response = await axios.get(process.env.REACT_APP_BACKEND + "crypto_detail?id=" + id, { headers: headers });
            let converted_data = convertCryptoData(response.data);
            setData(converted_data)
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Failed to fetch data. Please try again.", {
                theme: "dark",
            });
        } finally {
            setLoading(false);
        }
    };

    let start = (currentPage - 1) * recordsPerPage + 1;


    const fetchArticleData = async () => {
        setArticleLoading(true);
        try {
            let headers = {
                Authorization: `Bearer ${token}`
            }
            const response = await axios.get(process.env.REACT_APP_BACKEND + "news?words=" + data.slug + '&start=' + start + '&length=' + recordsPerPage, { headers: headers });
            let res = response?.data?.articles
            let converted_data = res?.results ? res?.results : [];
            setArticlesData(converted_data)
            setTotalRecordsArticle(res?.totalResults)
        } catch (error) {
            toast.error("Failed to fetch articles", {
                theme: "dark",
            });
        } finally {
            setArticleLoading(false);
        }
    }

    const getHistorical = async () => {
        setChartLoading(true)
        try {
            let headers = {
                Authorization: `Bearer ${token}`
            }
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND}crypto_historical?id=${id}${interval ? `&interval=${interval}` : ''}`,
                { headers: headers }
            );
            let res = response?.data
            setHistoricalData(res?.cryptoCurrency?.quote)
        } catch (error) {
            toast.error("Failed to fetch articles", {
                theme: "dark",
            });
            if (error.response.status === 401) {
                navigate('/login')
            }
        } finally {
            setChartLoading(false)
        }
    }

    useEffect(() => {
        if (data?.slug) {
            fetchArticleData();
        }
    }, [data.slug, start]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        getHistorical()
    }, [interval])


    const handlePageChange = (page) => {
        setCurrentPage(page);
    }
    function findThreshold(data) {
        console.log(data)
        if (!Array.isArray(data) || data.length === 0) {
            return 0;
        }
        // Sort the array in ascending order
        const sortedArray = [...data].sort((a, b) => a - b);

        // Find the lower half of the array
        const midIndex = Math.floor(sortedArray.length / 2);
        const lowerHalf = sortedArray.slice(0, midIndex);

        // Calculate the average of the lower half
        const averageLow = lowerHalf.reduce((sum, value) => sum + value, 0) / lowerHalf.length;

        return averageLow;
    }

    const prepareChartData = (quotes, type) => {
        const daysOfWeek = quotes?.map(e => e?.timestamp && moment(new Date(e?.timestamp)).format('Do MMM,YYYY'));

        return {
            labels: daysOfWeek, 
            datasets: [
                {
                    label: type === "price" ? "Price" : "Market Cap",
                    data: quotes?.map((item) => (type === "price" ? item.price : item.market_cap)),
                    segment: {
                        borderColor: (ctx) =>  //"#00E4FF",
                            ctx.p0.parsed.y > threshold ? "#00E4FF" : "#FF3B3B",
                    },
                    borderWidth: 2,
                    pointRadius: 0,
                },
            ],
        };
    };

    let chartData = prepareChartData(historicalData || [], selectedType);
    const threshold = findThreshold(chartData.datasets[0].data) || 0 // Example threshold for borderColor logic


    // render
    return (
        <div className="text-white">
            {loading && <PageLoader />}
            <p className='font-semibold text-2xl capitalize text-white mb-5 text-center md:text-start'>
                Hello <span className='text-[#3CC8C8]'>{getFirstName()}</span>
            </p>

            <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-5">
                {/* Left Panel */}
                <LeftBarMarketData img={btcIcon} cryptoData={data} />

                {/* Right Panel - Chart */}
                <div className="lg:col-span-3 bg-[#202F4C] p-4 rounded-2xl">
                    <ChartPanel btcIcon={data?.logo} cryptoData={data}
                        links={links} data={chartData} options={options}
                        selectedType={selectedType} setSelectedType={setSelectedType}
                        selectedRange={interval} setSelectedRange={setInterval}
                        loading={chartLoading} />
                </div>
            </div>
            {/* News Feed */}
            <NewsFeed
                title={data?.name + ' News'}
                articles={articlesData}
                loading={articleLoading}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                recordsFiltered={totalRecordsArticle}
                recordsPerPage={recordsPerPage} />
        </div>
    );
};

export default MarketDetails