import React from 'react'
import inboxIcon from '../../assets/images/message/inbox.svg'
import sendIcon from '../../assets/images/message/send.svg'
import { PlusIcon, Search } from 'lucide-react'
import Cookies from 'js-cookie';
import TokenParser from '../../Helpers/tokenParser';

function MessageMenu({ toggleMenu, activeView, setIsNewMsg = false, searchValue, setSearchValue }) {

    let token = Cookies.get('token');
    let details = TokenParser(token);

    return (
        <>
            {/* {details.payload.role !== 'admin' && */}
            <button
                className={`w-fit h-fit lg:min-w-[220px] bg-[#26DDB9] text-white text-center font-bold p-4 md:px-8 rounded-xl flex items-center gap-2`}
                onClick={() => setIsNewMsg(true)}
            >
                <PlusIcon />
                New Message
            </button>
            {/* } */}
            {details.payload.role === 'admin' &&
                <div className='relative'>
                    <Search className='absolute left-3 top-[10px]' size={18} color='#D6DAE0' />
                    <input
                        style={{ fontSize: '1rem' }}
                        className='ps-10 pe-4 text-md py-2 rounded-full text-[#D6DAE0] bg-[#37445E] outline-none'
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder='Search'
                        value={searchValue}
                    />
                </div>}
            <button
                className={`${activeView === 'inbox' ? 'border-l-4 border-l-[#26DDB9]' : 'rounded'} w-fit min-w-[150px] text-white font-bold py-2 px-4 flex items-center gap-2`}
                onClick={() => toggleMenu('inbox')}
            >
                <img src={inboxIcon} alt="inbox" className="w-6 h-6" />
                Inbox
            </button>
            <button
                className={`${activeView === 'sent' ? 'border-l-4 border-l-[#26DDB9]' : 'rounded'} w-fit min-w-[150px] text-white font-bold py-2 px-4 flex items-center gap-2`}
                onClick={() => toggleMenu('sent')}
            >
                <img src={sendIcon} alt="send" className="w-6 h-6" />
                Sent
            </button>
        </>
    )
}

export default MessageMenu