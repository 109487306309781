import React, { useEffect, useRef, useState } from "react";
import botIcon from '../../assets/images/user/botIcon.svg'

const MemuTrades = () => {
    const [trades, setTrades] = useState([]);
    const [loading, setLoading] = useState(true);

    // Ref for the scrollable container
    const tradesContainerRef = useRef(null);

    // Auto-scroll to bottom whenever trades are updated
    useEffect(() => {
        if (tradesContainerRef.current) {
            tradesContainerRef.current.scrollTop = tradesContainerRef.current.scrollHeight;
        }
    }, [trades]);

    useEffect(() => {
        // Function to fetch data from the backend
        const fetchMessages = async () => {
            try {
                setLoading(true); // Start loading
                const response = await fetch("https://b5dd6qmxnarqdr36piyjnouegy0jphol.lambda-url.ap-south-1.on.aws/api/v1/crawl?action=perform");
                if (!response.ok) {
                    throw new Error("Failed to fetch messages");
                }
                const data = await response.json();
                setTrades(data?.data); // Update the state with the fetched messages
            } catch (error) {
                console.error("Error fetching messages:", error.message);
            } finally {
                setLoading(false); // End loading
            }
        };

        fetchMessages(); // Call the fetch function
    }, []);

    return (
        <div className={`text-white rounded-2xl h-full`}>
            {/* Header */}
            <div className="rounded-t-2xl p-6 text-start" style={{ background: 'linear-gradient(76.84deg, #7770FF 13.58%, #3CC8C8 89.71%)' }}>
                <img src={botIcon} alt="botIcon" className="h-10 w-10 mb-2" />
                <h1 className="text-2xl font-bold mb-2">MEMU TRADES</h1>
                <p className="text-sm text-white md:max-w-md">
                    A live chatbot which lets you know what's new in the crypto space
                </p>
            </div>

            {/* Trades Section */}
            {loading ? (
                <div className="space-y-4 -mt-3 bg-[#212F4C] p-5 rounded-b-2xl">
                    {/* Loader Header */}
                    <div className="mt-3 w-full h-44 bg-[#07142F] rounded-md animate-pulse"></div>

                    {/* Loader Body */}
                    <div className="space-y-3">
                        <div className="w-full h-8 bg-[#07142F] rounded-md animate-pulse"></div>
                        <div className="w-full h-8 bg-[#07142F] rounded-md animate-pulse"></div>
                        <div className="w-full h-8 bg-[#07142F] rounded-md animate-pulse"></div>
                        <div className="w-full h-8 bg-[#07142F] rounded-md animate-pulse"></div>
                        <div className="w-full h-8 bg-[#07142F] rounded-md animate-pulse"></div>
                        <div className="w-5/6 h-8 bg-[#07142F] rounded-md animate-pulse"></div>
                        <div className="w-6/3 h-8 bg-[#07142F] rounded-md animate-pulse"></div>
                    </div>

                    {/* Loader Footer */}
                    <div className="w-full h-14 bg-[#07142F] rounded-md animate-pulse"></div>
                </div>
            ) : (
                <div
                    ref={tradesContainerRef}
                    className="relative rounded-b-2xl space-y-12 p-4 bg-[#212F4C] h-[37.5rem] overflow-y-auto scrollbar-none ps-10">
                    {trades.map((trade, index) => (
                        <div
                            key={index}
                            className="bg-[#2D3A58] p-4 rounded-xl shadow-lg text-sm"
                        >
                            <pre className="text-[#62D2F2] font-medium" style={{
                                whiteSpace: 'pre-wrap',
                                fontFamily: 'monospace'
                            }}>✨{trade?.message}</pre>

                            {/* avatar icon */}
                            <img src={botIcon} alt="botIcon"
                                className="h-10 w-10 p-2 mt-3 rounded-full bg-[#26DDB9] absolute left-3" />
                        </div>
                    ))}
                </div>)}
        </div>
    );
};

export default MemuTrades;
